import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";
import { jwtDecode } from "jwt-decode";
import { LOGIN, LOGOUT } from "store/actions";
import accountReducer from "store/accountReducer";
import Loader from "components/Loader";
import { addData, getData } from "services";
import { logoutEvent } from "utils/socket";

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem("serviceToken", serviceToken);
    } else {
        localStorage.removeItem("serviceToken");
    }
};

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = localStorage.getItem("serviceToken");
                if (serviceToken && verifyToken(serviceToken)) {
                    setSession(serviceToken);
                    const response = await getData("/en/admin/me");

                    if(response && response.data) {
                      const { data } = response.data;
                      dispatch({
                          type: LOGIN,
                          payload: {
                              isLoggedIn: true,
                              user: data
                          }
                      });
                    } else {
                      dispatch({
                          type: LOGOUT
                      });
                    }
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    const login = async (email, password) => {
        try {
            const response = await addData(
                "en/auth/login",
                {
                    email,
                    password
                },
            );

            // Set token and update state
            const { token } = response.data;
            setSession(token);

            // get the user data
            const userData = await getData("/en/admin/me");
            const { data } = userData.data;

            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    user: data
                }
            });
        } catch (error) {
            const errorMessage = error.response?.data?.err?.message || error?.data?.err?.message || error?.err?.message || 'Login failed';
            // Throw the error message to be handled in AuthLogin
            throw new Error(errorMessage);
        }
    };

    const logout = () => {
        setSession(null);
        logoutEvent()
        dispatch({ type: LOGOUT });
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider
            value={{
                ...state,
                login,
                logout
            }}
        >
            {children}
        </JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
