import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../index";
import { getData } from "services";

const initialState = {
    error: null,
    orderDifferenceData: {},
    orderComulativeData: {},
    chartByCountryData: {},
    chartData: { ordersChartData: {}, revenueChartData: {} },
    loading: false
};

const slice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        getOrderDifferenceSuccess(state, action) {
            state.orderDifferenceData = action.payload;
        },

        getOrderComulativeSuccess(state, action) {
            state.orderComulativeData = action.payload;
        },

        getChartByCountrySuccess(state, action) {
            state.chartByCountryData = action.payload;
        },

        getChartSuccess(state, action) {
            state.chartData = action.payload;
        },

        hasLoading(state, action) {
            state.loading = action.payload;
        }
    }
});

export default slice.reducer;

export function getOrderDifferenceData() {
    return async () => {
        dispatch(slice.actions.hasLoading(true));
        try {
            const response = await getData("en/admin/order-differences");
            dispatch(slice.actions.getOrderDifferenceSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
        dispatch(slice.actions.hasLoading(false));
    };
}

export function getOrderComulativeData(period) {
    return async () => {
        dispatch(slice.actions.hasLoading(true));
        try {
            const response = await getData(`en/admin/order-comulative-stats?period=${period}`);
            dispatch(slice.actions.getOrderComulativeSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
        dispatch(slice.actions.hasLoading(false));
    };
}

export function getChartByCountryData(period) {
    return async () => {
        dispatch(slice.actions.hasLoading(true));
        try {
            const response = await getData(`en/admin/order-chart-by-country-stats?period=${period}`);
            const total = {};
            const completed = {};
            const rejected = {};
            const in_progress = {};
            const blocked = {};
            const lables = [];

            response?.data?.data?.items?.orders?.forEach((row) => {
                const key = row.destination_country;
                total[key] = row.total;
                completed[key] = row.completed;
                rejected[key] = row.rejected;
                in_progress[key] = row.in_progress;
                blocked[key] = row.blocked;
                lables.push(key);
            });

            const chartData = {
                chartLabel: lables,
                linesData: {
                    total: Object.values(total),
                    completed: Object.values(completed),
                    in_progress: Object.values(in_progress),
                    blocked: Object.values(blocked),
                    rejected: Object.values(rejected)
                }
            };
            dispatch(slice.actions.getChartByCountrySuccess(chartData));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
        dispatch(slice.actions.hasLoading(false));
    };
}

export function getChartData(period) {
    return async () => {
        dispatch(slice.actions.hasLoading(true));
        try {
            const response = await getData(`en/admin/order-chart-stats?period=${period}`);
            const total = {};
            const total_revenue = {};
            const completed = {};
            const rejected = {};
            const in_progress = {};
            const blocked = {};
            const lables = [];
            const total_amount = {};

            response?.data?.data?.items?.orders?.forEach((row) => {
                const key = row.date_time_value;

                if (lables.includes(key)) {
                    total[key] += row.total;
                    completed[key] += row.completed;
                    rejected[key] += row.rejected;
                    in_progress[key] += row.in_progress;
                    blocked[key] += row.blocked;
                    total_revenue[key] += row.total_revenue;
                    total_amount[key] += row.total_amount;
                } else {
                    lables.push(key);
                    total[key] = row.total;
                    completed[key] = row.completed;
                    rejected[key] = row.rejected;
                    in_progress[key] = row.in_progress;
                    blocked[key] = row.blocked;
                    total_revenue[key] = row.total_revenue;
                    total_amount[key] = row.total_amount;
                }
            });

            const ordersChartData = {
                chartLabel: lables,
                linesData: {
                    total: Object.values(total),
                    in_progress: Object.values(in_progress),
                    completed: Object.values(completed),
                    blocked: Object.values(blocked),
                    rejected: Object.values(rejected)
                }
            };

            const revenueChartData = {
                chartLabel: lables,
                linesData: {
                    completed: Object.values(completed),
                    total_amount: Object.values(total_amount).map(el => parseInt(el))
                }
            };

            dispatch(slice.actions.getChartSuccess({ ordersChartData, revenueChartData }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
        dispatch(slice.actions.hasLoading(false));
    };
}
