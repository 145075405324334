import { IconUsers, IconUserX, IconBrush} from "@tabler/icons";
import { ROLES } from "constants";
import {
    BlockOutlined,
    PrivacyTipOutlined,
    VerifiedUserOutlined,
    HourglassTopOutlined
} from "@mui/icons-material";
import { IconUsersGroup } from "@tabler/icons-react";

const customers = {
    id: "customers",
    title: "Customers",
    icon: IconBrush,
    type: "group",
    children: [
        {
            id: "all",
            title: "All",
            type: "item",
            icon: IconUsersGroup,
            url: "/accounts",
            breadcrumbs: false,
            role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
        },
        {
            id: "unverified",
            title: "Unverified",
            type: "collapse",
            icon: PrivacyTipOutlined,
            // url: "/unverified-users",
            // breadcrumbs: false,
            children: [
                {
                    id: "active",
                    title: "Active",
                    type: "item",
                    icon: HourglassTopOutlined,
                    url: "/unverified-users",
                    breadcrumbs: false,
                    role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
                },
                {
                    id: "history",
                    title: "History",
                    type: "item",
                    icon: VerifiedUserOutlined,
                    url: "/unverified-history",
                    breadcrumbs: false,
                    role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
                }
            ]
        },
        {
            id: "blocked",
            title: "Blocked",
            type: "item",
            icon: BlockOutlined,
            url: "/blocked-users",
            breadcrumbs: false,
            role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
        },
        {
            id: "recipients",
            title: "Recipients",
            type: "collapse",
            icon: IconUsers,
            children: [
                {
                    id: "all",
                    title: "All",
                    type: "item",
                    icon: IconUsersGroup,
                    url: "/recipients",
                    breadcrumbs: false,
                    role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
                },
                {
                    id: "blacklist",
                    title: "Blacklist",
                    type: "item",
                    icon: IconUserX,
                    url: "/blacklisted-recipients",
                    breadcrumbs: false,
                    role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
                }
            ]
        }
    ]
};

export default customers;
