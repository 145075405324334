import * as React from 'react';
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { Grid, Typography } from "@mui/material";
import { DATETIME_FORMAT } from "services/format";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent
} from "@mui/lab";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ErrorIcon from '@mui/icons-material/Error';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BlockIcon from '@mui/icons-material/Block';
import RepeatIcon from '@mui/icons-material/Repeat';
import PaidIcon from '@mui/icons-material/Paid';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import PolicyIcon from '@mui/icons-material/Policy';

import SubCard from "components/Cards/SubCard";
import { GRID_SPACING } from "constants";

const Status = ({ data }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const getStatusIcon = (status) => {
    switch (status) {
      case "payment.created":
        return <ShoppingCartIcon />;
      case "Placed":
        return <ShoppingCartIcon />;
      case "payment.charge.failed":
        return <ErrorIcon />;
      case "payment.charge.created.v2":
        return <PaidIcon />;
      case "Rejected":
        return <CancelIcon />;
      case "Delivered":
        return <CheckCircleIcon />;
      case "Ready To Deliver":
        return <HourglassTopIcon />;
      case "Blocked":
        return <BlockIcon />;
      case "Compliance Checking":
        return <PolicyIcon />;
      case "Under-Verification":
        return <PolicyIcon />;
      default:
        return <RepeatIcon />; // Fallback for unknown status
    }
  };

  return (
    <SubCard>
      <Grid container spacing={GRID_SPACING}>
        <Grid item>
          <Timeline
            position={isSmallScreen ? "right" : "alternate"}
            sx={{
              "& > li": {
                mb: 2.75,
                [theme.breakpoints.down("md")]: {
                  flexDirection: "column",
                  alignItems: "flex-start",
                  "& > div": {
                    px: 0,
                  },
                  "& > div:first-of-type": {
                    textAlign: "left",
                  }
                }
              },
              [theme.breakpoints.down("md")]: {
                p: 0,
              }
            }}
          >
            {data.map((el, index) => (
              el.status !== "payment.created" && ( // Skip if status is "payment.created"
                <TimelineItem key={index}>
                  <TimelineOppositeContent
                    color="text.primary"
                    variant="h5"
                    sx={{
                      m: '0', // No margin
                      flex: isSmallScreen ? 0 : 1,
                      fontSize: isSmallScreen ? '0.835rem' : '0.875rem',
                      // Add top margin for larger screens
                      mt: isSmallScreen ? 0 : 2
                    }}
                  >
                    {DATETIME_FORMAT(el.date)}
                  </TimelineOppositeContent>

                  {/* Hide TimelineSeparator completely on small screens */}
                  {!isSmallScreen && (
                    <TimelineSeparator>
                      <TimelineDot color="primary">
                        {getStatusIcon(el.status)}
                      </TimelineDot>
                      {index < data.length - 1 && (
                        <TimelineConnector sx={{ height: 40, bgcolor: "grey.500" }} />
                      )}
                    </TimelineSeparator>
                  )}

                  <TimelineContent
                    sx={{
                      py: '0', // Remove vertical padding
                      pl: isSmallScreen ? 1 : 2, // Control left padding based on screen size
                      flex: 1, // Allow the content to take available space
                    }}
                  >
                    <Typography
                      variant="h6"
                      component="span"
                      sx={{ fontSize: isSmallScreen ? '0.775rem' : '0.875rem' }} // Adjusted font size for titles
                    >
                      {el.status === "payment.charge.created.v2" ? "Charged" :
                        el.status === "payment.created" ? "Created" :
                          el.status}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      sx={{ fontSize: isSmallScreen ? '0.75rem' : '0.875rem' }} // Adjusted font size for remarks
                    >
                      {el.remarks}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              )
            ))}
          </Timeline>
        </Grid>
      </Grid>
    </SubCard>
  );
};

export default Status;
