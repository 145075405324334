import React from "react";
import { useTheme } from "@mui/material/styles";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Stack,
    TextField
} from "@mui/material";
import { Form, Formik } from "formik";
import { GRID_SPACING } from "constants";
import { updateData } from "services";
import { encrypt } from "utils/encDes";
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  current_password: Yup.string().required("Current Password is required"),
  new_password: Yup
    .string()
    .required("New Password is required")
    .min(8, "Minimum Length should be 8")
    .matches(/[A-Z]/, "Password must contain at least 1 uppercase letter")
    .matches(/[a-z]/, "Password must contain at least 1 lowercase letter")
    .matches(/[0-9]/, "Password must contain at least 1 number")
    .test(
      "no-alphanumeric-only",
      "Password must contain at least 1 special character",
      (value) => /[^a-zA-Z0-9]/.test(value)
    ),
  confirm_password: Yup
    .string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref('new_password'), null], 'Password and Confirm Password must be match.'),
})

const ChangePasswordDialog = ({ open, setOpen, userId }) => {
    const theme = useTheme();

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                {open && (
                    <Formik
                        initialValues={{
                            current_password: "",
                            new_password: "",
                            confirm_password: ""
                        }}
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                        onSubmit={(values, { resetForm }) => {
                            if (values) {
                                const slug = `/en/admin/users/${encrypt(userId)}/change-password`;
                                updateData(slug, values).finally(() => handleClose());
                            }
                            resetForm();
                        }}
                    >
                        {({ values, handleChange, handleBlur, errors, touched }) => (
                            <Form>
                                <DialogContent>
                                    <Grid container width={400} spacing={GRID_SPACING}>
                                        <Grid item xs={12}>
                                            <Stack>
                                                <TextField
                                                    fullWidth
                                                    type="password"
                                                    name="current_password"
                                                    label="Current Password"
                                                    value={values.current_password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.current_password && touched.current_password ? (
                                                    <div className="error">{errors.current_password}*</div>
                                                ) : null}
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack>
                                                <TextField
                                                    fullWidth
                                                    type="password"
                                                    name="new_password"
                                                    label="New Password"
                                                    value={values.new_password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.new_password && touched.new_password ? (
                                                    <div className="error">{errors.new_password}*</div>
                                                ) : null}
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack>
                                                <TextField
                                                    fullWidth
                                                    type="password"
                                                    name="confirm_password"
                                                    label="Confirm Password"
                                                    value={values.confirm_password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.confirm_password && touched.confirm_password ? (
                                                    <div className="error">{errors.confirm_password}*</div>
                                                ) : null}
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions sx={{ pr: 2.5 }}>
                                    <Button
                                        sx={{ color: theme.palette.error.dark }}
                                        onClick={handleClose}
                                        color="secondary"
                                    >
                                        Cancel
                                    </Button>
                                    <Button variant="contained" size="small" type="submit">
                                        Update Password
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                )}
            </Dialog>
        </div>
    );
}

export default ChangePasswordDialog;
