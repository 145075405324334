import axios from "utils/axios";

export const getData = async (url) => {
  try {
    const response = await axios.get(url);
    return response;
  } catch (err) {
    console.log("Error while making request", url);
    return err;
  }
};

export const addData = async (url, payload) => {
  try {
    const response = await axios.post(url, payload);
    return response;
  } catch (err) {
    console.log("Error while making request", url, payload);
    return err;
  }
};

export const updateData = async (url, payload) => {
  try {
    const response = await axios.put(url, payload);
    return response;
  } catch (err) {
    console.log("Error while making request", url, payload);
    return err;
  }
};

export const updatePatchData = async (url, payload) => {
  try {
    const response = await axios.patch(url, payload);
    return response;
  } catch (err) {
    console.log("Error while making request", url, payload);
    return err;
  }
};

export const deleteData = async (url) => {
  try {
    const response = await axios.delete(url);
    return response;
  } catch (err) {
    console.log("Error while making request", url);
    return err;
  }
};
