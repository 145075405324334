import { Button, FormControl, Grid, Typography } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';

const AddButton = ({ addData, text = null }) => {
    return (
        <Grid item style={{ marginLeft: "20px" }}>
            <FormControl>
                <Button size="large" variant="outlined" onClick={addData}>
                    <AddCircleIcon />
                    {text && (
                        <Typography sx={{marginLeft: 1, fontWeight: 600}}>{text}</Typography>
                    )}
                </Button>
            </FormControl>
        </Grid>
    );
};

export default AddButton;
