import { useNavigate, useRoutes } from 'react-router-dom';
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import { useEffect, useState } from 'react';
import { disconnect, joinRoom, leaveRoom, listenForUserStatus } from 'utils/socket';
import useAuth from "hooks/useAuth";
import { useSocket } from 'hooks/useSocket';

const ThemeRoutes = () => {
  const [routes, setRoutes] = useState([LoginRoutes]);

  const { user, logout, isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const socket = useSocket('admins', isLoggedIn);

  useEffect(() => {
    if (user && user.session_id) {
      joinRoom(socket, `session_${user.session_id}`);

      const userData = listenForUserStatus(socket);

      if (userData && userData.sessionId === user.session_id) {
        logout();
        localStorage.clear();
        disconnect(socket);
        navigate("/login");
      }

      return () => {
        leaveRoom(socket, `session_${user.session_id}`);
      };
    }
  }, [user]);

  useEffect(() => {
    const fetchFilteredRoutes = async () => {
      const role = user?.role;
      const filteredRoutes = MainRoutes.children.filter((route) => route.role.includes(role));
      setRoutes([LoginRoutes, { ...MainRoutes, children: filteredRoutes }]);
    };

    if (routes.length === 1 || routes[1]?.children?.length === 0) {
      fetchFilteredRoutes();
    }
  }, [user]);

  return useRoutes(routes);
};

export default ThemeRoutes;
