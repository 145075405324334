import {
    Button,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import MainCard from "components/Cards/MainCard";
import { useDispatch, useSelector } from "store";
import { useEffect, useState } from "react";
import { TableData } from "components/Table/index";
import {
    DATETIME_FORMAT,
    FULL_NAME_FORMAT,
    LOG_DETAIL_FORMAT,
    LOG_STATUS_FORMAT,
} from "services/format";
import MessageHistoryLogDialog from "components/Dialog/MessageHistoryLog";
import { useFormik } from "formik";
import { GRID_SPACING, ROW_PER_PAGE } from "constants";
import { useTheme } from "@emotion/react";
import { getMessageHistoryLogsData } from "store/slices/message-history-logs";

const MessageHistoryLog = () => {
    // table header options
    const headCells = [
        {
            id: "user_no",
            label: "Customer ID",
        },
        {
            id: "updated_at",
            label: "Last Changed On",
            align: 'left',
            format: (v, r, c) => DATETIME_FORMAT(v, r, c),
            sortable: true
        },
        {
            id: "first_name",
            label: "Customer",
            format: (v, r, c) => FULL_NAME_FORMAT(v, r, c)
        },
        {
            id: "personal_number",
            label: "Personal No",
            cellClickAction: (row) => setLogData(row),
            format: (v, r, c) => LOG_DETAIL_FORMAT(v, r, c),
            sortable: true
        },
        {
            id: "mobile_no",
            label: "Mobile",
            sortable: true
        },
        {
            id: "message_type",
            label: "Message Type",
            format: (v, r, c) => LOG_STATUS_FORMAT(v, r, c),
            align: 'center',
            sortable: true
        },
        {
            id: "message_sending_type",
            label: "Delivery Schedule",
            format: (v, r, c) => LOG_STATUS_FORMAT(v, r, c),
            align: 'center',
            sortable: true
        },
        {
            id: "status",
            label: "Delivery Status",
            format: (v, r, c) => LOG_STATUS_FORMAT(v, r, c),
            align: 'center',
            sortable: true
        },
        {
            id: "action_triggered",
            label: "Email/SMS Against",
            align: 'center',
            sortable: true
        },
        {
            id: "scheduled_time",
            label: "Scheduled Time",
            align: 'left',
            format: (v, r, c) => DATETIME_FORMAT(v, r, c),
            sortable: true
        },
        {
            id: "sent_time",
            label: "Sent Time",
            align: 'left',
            format: (v, r, c) => DATETIME_FORMAT(v, r, c),
            sortable: true
        },
        {
            id: "delivered_time",
            label: "Delivered Time",
            align: 'left',
            format: (v, r, c) => DATETIME_FORMAT(v, r, c),
            sortable: true
        },
        {
            id: "subject",
            label: "Subject",
        },
        {
            id: "failure_reason",
            label: "Failure Reason",
        },
    ];

    const dispatch = useDispatch();

    const theme = useTheme();

    const [logData, setLogData] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(ROW_PER_PAGE);
    const { messageHistoryLogsData, loading } = useSelector(
        (state) => state.messageHistoryLogs
    );

    const formik = useFormik({
        initialValues: {
            name: null,
            personal_number: null,
            mobile: null,
            message_type: null,
            message_sending_type: null,
            status: null,
            action_triggered: null
        },
        enableReinitialize: true,
        onSubmit: async (values) => {
            refreshData(`?filters=${JSON.stringify(values)}`);
        }
    });

    useEffect(() => {
        refreshData();
    }, []);

    const refreshData = (
        query = `?offset=${page * rowsPerPage}&limit=${rowsPerPage}`
    ) => {
        dispatch(getMessageHistoryLogsData(query));
    };

    const handleReset = () => {
        formik.handleReset();
        refreshData();
    };

    const onSort = (key, order) => {
        refreshData(
            `?offset=${page * rowsPerPage}&limit=${rowsPerPage}&sort={"${key}":"${order.toUpperCase()}"}`
        );
    };

    const onPageChange = (page = 0, limit = ROW_PER_PAGE) => {
        setPage(page);
        setRowsPerPage(limit);
        let query = `?offset=${page * limit}&limit=${limit}`;
        refreshData(query);
    };

    return (
        <MainCard content={false}>
            <CardContent>
                <Grid container alignItems="center">
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={GRID_SPACING}>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    id="name"
                                    name="name"
                                    label="Customer"
                                    value={formik.values.name || ""}
                                    onChange={formik.handleChange}
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    id="personal_number"
                                    name="personal_number"
                                    label="Personal No"
                                    value={formik.values.personal_number || ""}
                                    onChange={formik.handleChange}
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    id="mobile"
                                    name="mobile"
                                    label="Mobile No"
                                    value={formik.values.mobile || ""}
                                    onChange={formik.handleChange}
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="message_type" size="small">
                                        Message Type
                                    </InputLabel>
                                    <Select
                                        id="message_type"
                                        name="message_type"
                                        label="Message Type"
                                        value={formik.values.message_type || ""}
                                        onChange={formik.handleChange}
                                        size="small"
                                    >
                                        <MenuItem value="EMAIL">Email</MenuItem>
                                        <MenuItem value="SMS">SMS</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="message_sending_type" size="small">
                                        Delivery Scheduled
                                    </InputLabel>
                                    <Select
                                        id="message_sending_type"
                                        name="message_sending_type"
                                        label="Delivery Scheduled"
                                        value={formik.values.message_sending_type || ""}
                                        onChange={formik.handleChange}
                                        size="small"
                                    >
                                        <MenuItem value="IMMEDIATE">IMMEDIATE</MenuItem>
                                        <MenuItem value="SCHEDULED">SCHEDULED</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="status" size="small">
                                        Delivery Status
                                    </InputLabel>
                                    <Select
                                        id="status"
                                        name="status"
                                        label="Delivery Status"
                                        value={formik.values.status || ""}
                                        onChange={formik.handleChange}
                                        size="small"
                                    >
                                        <MenuItem value="PENDING">Pending</MenuItem>
                                        <MenuItem value="SENT">Sent</MenuItem>
                                        <MenuItem value="POSTPONED">Postponed</MenuItem>
                                        <MenuItem value="DELIVERED">Delivered</MenuItem>
                                        <MenuItem value="FAILED">Failed</MenuItem>
                                        <MenuItem value="RETRIED">Retried</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="action_triggered" size="small">
                                        Email/SMS Against
                                    </InputLabel>
                                    <Select
                                        id="action_triggered"
                                        name="action_triggered"
                                        label="Email/SMS Against"
                                        value={formik.values.action_triggered || ""}
                                        onChange={formik.handleChange}
                                        size="small"
                                    >
                                        <MenuItem value="NO ORDER TILL REGISTERED">NO ORDER TILL REGISTERED</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                xs={3}
                                sx={{ marginBottom: 0.5 }}
                                display={"flex"}
                                justifyContent={"space-between"}
                            >
                                <Button
                                    sx={{
                                        color: theme.palette.error.dark,
                                        borderColor: theme.palette.error.dark,
                                        width: 80
                                    }}
                                    color="secondary"
                                    type="reset"
                                    variant="outlined"
                                    size="small"
                                    onClick={handleReset}
                                >
                  Reset
                                </Button>
                                <Button
                                    variant="contained"
                                    size="small"
                                    type="submit"
                                    sx={{ width: 80 }}
                                >
                  Search
                                </Button>
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="success"
                                    onClick={() => refreshData()}
                                    sx={{ width: 80 }}
                                >
                  Reload
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </CardContent>

            {/* table */}
            <TableData
                rows={messageHistoryLogsData}
                headCells={headCells}
                onSort={onSort}
                loading={loading}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={onPageChange}
            />

            {logData && (
                <MessageHistoryLogDialog
                    open={!!logData}
                    setOpen={() => setLogData(null)}
                    data={logData}
                />
            )}
        </MainCard>
    );
};

export default MessageHistoryLog;
