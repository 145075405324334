import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../index";
import { ORDER_STATUS } from "constants";
import { getData } from "services";

const initialState = {
    error: null,
    orders: [],
    placedOrders: [],
    readyToDeliverOrders: [],
    deliveredOrders: [],
    rejectedOrders: [],
    failedOrders: [],
    blockedOrders: [],
    loading: false
};

const slice = createSlice({
    name: "order",
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        getOrdersSuccess(state, action) {
            state.orders = action.payload;
        },

        getPlacedOrdersSuccess(state, action) {
            state.placedOrders = action.payload;
        },

        getReadyToDeliverOrdersSuccess(state, action) {
            state.readyToDeliverOrders = action.payload;
        },

        getDeliveredOrdersSuccess(state, action) {
            state.deliveredOrders = action.payload;
        },

        getRejectedOrdersSuccess(state, action) {
            state.rejectedOrders = action.payload;
        },

        getFailedOrdersSuccess(state, action) {
            state.failedOrders = action.payload;
        },

        getBlockedOrdersSuccess(state, action) {
            state.blockedOrders = action.payload;
        },

        hasLoading(state, action) {
            state.loading = action.payload;
        }
    }
});

export default slice.reducer;

export function getOrders(order_status="", query="") {
    return async () => {
        dispatch(slice.actions.hasLoading(true));
        try {
            const orderStatus = order_status ? JSON.stringify([order_status]) : "";
            const response = await getData(
                "en/admin/orders?order_status=" + encodeURIComponent(orderStatus) + query
            );

            if (!order_status) {
                dispatch(slice.actions.getOrdersSuccess(response.data.data));
            } else if (order_status === ORDER_STATUS.PLACED) {
                dispatch(slice.actions.getPlacedOrdersSuccess(response.data.data));
            } else if (order_status === ORDER_STATUS.READY_TO_DELIVER) {
                dispatch(
                    slice.actions.getReadyToDeliverOrdersSuccess(response.data.data)
                );
            } else if (order_status === ORDER_STATUS.DELIVERED) {
                dispatch(slice.actions.getDeliveredOrdersSuccess(response.data.data));
            } else if (order_status === ORDER_STATUS.REJECTED) {
                dispatch(slice.actions.getRejectedOrdersSuccess(response.data.data));
            } else if (order_status === ORDER_STATUS.FAILED) {
                dispatch(slice.actions.getFailedOrdersSuccess(response.data.data));
            } else if (order_status === ORDER_STATUS.BLOCKED) {
                dispatch(slice.actions.getBlockedOrdersSuccess(response.data.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
        dispatch(slice.actions.hasLoading(false));
    };
}
