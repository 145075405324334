import React, { useMemo, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import {
    AppBar,
    Box,
    Container,
    CssBaseline,
    Toolbar,
    useMediaQuery
} from "@mui/material";
import Breadcrumbs from "components/Extended/Breadcrumbs";
import Header from "./Header";
import Sidebar from "./Sidebar";
import navigation from "menu-items";
import useConfig from "hooks/useConfig";
import { DRAWER_WIDTH, LAYOUT_CONST } from "constants";
import { openDrawer } from "store/slices/menu";
import { useDispatch, useSelector } from "store";
import { IconChevronRight } from "@tabler/icons";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open, layout }) => ({
        ...theme.typography.mainContent,
        ...(!open && {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.shorter + 200
            }),
            [theme.breakpoints.up("md")]: {
                marginLeft:
          layout === LAYOUT_CONST.VERTICAL_LAYOUT ?
              -(DRAWER_WIDTH - 72) :
              "20px",
                width: `calc(100% - ${DRAWER_WIDTH}px)`,
                marginTop: layout === "horizontal" ? 135 : 80
            },
            [theme.breakpoints.down("md")]: {
                marginLeft: "20px",
                width: `calc(100% - ${DRAWER_WIDTH}px)`,
                padding: "16px",
                marginTop: 80
            },
            [theme.breakpoints.down("sm")]: {
                marginLeft: "10px",
                width: `calc(100% - ${DRAWER_WIDTH}px)`,
                padding: "10px",
                marginRight: "10px",
                marginTop: 80
            }
        }),
        ...(open && {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.shorter + 200
            }),
            marginLeft: layout === "horizontal" ? "20px" : 0,
            marginTop: layout === "horizontal" ? 135 : 80,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            width: `calc(100% - ${DRAWER_WIDTH}px)`,
            [theme.breakpoints.up("md")]: {
                marginTop: layout === "horizontal" ? 135 : 80
            },
            [theme.breakpoints.down("md")]: {
                marginLeft: "20px",
                marginTop: 80
            },
            [theme.breakpoints.down("sm")]: {
                marginLeft: "10px",
                marginTop: 80
            }
        })
    })
);

const MainLayout = () => {
    const theme = useTheme();

    const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { container, drawerType, layout } = useConfig();

    useEffect(() => {
        if (drawerType === LAYOUT_CONST.DEFAULT_DRAWER) {
            dispatch(openDrawer(true));
        } else {
            dispatch(openDrawer(false));
        }
    }, [drawerType]);

    useEffect(() => {
        if (drawerType === LAYOUT_CONST.DEFAULT_DRAWER) {
            dispatch(openDrawer(true));
        }
    }, []);

    useEffect(() => {
        if (matchDownMd) {
            dispatch(openDrawer(true));
        }
    }, [matchDownMd]);

    const condition = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd;

    const header = useMemo(
        () => (
            <Toolbar sx={{ p: condition ? "10px" : "16px" }}>
                <Header />
            </Toolbar>
        ),
        [layout, matchDownMd]
    );

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />

            <AppBar
                position="fixed"
                elevation={0}
                sx={{
                    bgcolor: "background.default",
                    transition: drawerOpen ? theme.transitions.create("width") : "none",
                    boxShadow: 3
                }}
            >
                {header}
            </AppBar>

            {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || matchDownMd) && <Sidebar />}

            <Main
                theme={theme}
                open={drawerOpen}
                layout={layout}
                sx={{ marginRight: 0 }}
            >
                <Container
                    maxWidth={container ? "lg" : false}
                    {...(!container && { sx: { px: { xs: 0 } } })}
                    sx={{ margin: 0, padding: "0 !important" }}
                >
                    <Breadcrumbs
                        separator={IconChevronRight}
                        navigation={navigation}
                        icon
                        title
                        rightAlign
                    />
                    <Outlet />
                </Container>
            </Main>
        </Box>
    );
};

export default MainLayout;
