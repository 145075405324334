import {
    IconSettings,
    IconWorld,
    IconExchange,
    IconTruckDelivery,
    IconCurrencyDollar,
    IconHeartHandshake,
} from "@tabler/icons";
import { IconTransfer } from "@tabler/icons-react";
import { ROLES } from "constants";

const config = {
    id: "config",
    title: "Config",
    icon: IconSettings,
    type: "group",
    children: [
        {
            id: "countries",
            title: "Countries",
            type: "item",
            icon: IconWorld,
            url: "/configuration/countires",
            breadcrumbs: false,
            role: [ROLES.SUPER_ADMIN]
        },
        {
            id: "exchange-rate",
            title: "Exchange Rate",
            type: "item",
            icon: IconExchange,
            url: "/configuration/currency-rate",
            breadcrumbs: false,
            role: [ROLES.SUPER_ADMIN]
        },
        {
            id: "delivery-services",
            title: "Delivery Services",
            type: "item",
            icon: IconTruckDelivery,
            url: "/configuration/delivery-services",
            breadcrumbs: false,
            role: [ROLES.SUPER_ADMIN]
            // children: [
            //     {
            //         id: "service",
            //         title: "service",
            //         type: "item",
            //         icon: IconTruckDelivery,
            //         url: "/configuration/delivery-services",
            //         breadcrumbs: false
            //     },
            //     {
            //         id: "network",
            //         title: "network",
            //         type: "item",
            //         icon: IconAccessPoint,
            //         url: "/configuration/delivery-services/network",
            //         breadcrumbs: false
            //     },
            //     {
            //         id: "type",
            //         title: "type",
            //         type: "item",
            //         icon: IconAccessPoint,
            //         url: "/configuration/delivery-services/type",
            //         breadcrumbs: false
            //     }
            // ]
        },
        {
            id: "payment-source",
            title: "Payment Source",
            type: "item",
            icon: IconCurrencyDollar,
            url: "/configuration/payment-source",
            breadcrumbs: false,
            role: [ROLES.SUPER_ADMIN]
        },
        {
            id: "transfer-purpose",
            title: "Transfer purpose",
            type: "item",
            icon: IconTransfer,
            url: "/configuration/payment-purpose",
            breadcrumbs: false,
            role: [ROLES.SUPER_ADMIN]
        },
        {
            id: "relation-with-recipient",
            title: "Relation With Recipient",
            type: "item",
            icon: IconHeartHandshake,
            url: "/configuration/recipient-relation",
            breadcrumbs: false,
            role: [ROLES.SUPER_ADMIN]
        }
    ]
};

export default config;
