import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Typography
} from "@mui/material";
import { Form, Formik } from "formik";
import { REQUEST_DOCUMENT, AWS_CONFIG, GRID_SPACING } from "constants";
import AWS from "aws-sdk";
import DeleteIcon from "@mui/icons-material/Delete";
import { addData } from "services";
import { encrypt } from "utils/encDes";

const UploadDocument = ({ open, setOpen, userId }) => {
    const theme = useTheme();

    const s3 = new AWS.S3({
        accessKeyId: AWS_CONFIG.AWS_S3_ACCESS_KEY_ID,
        secretAccessKey: AWS_CONFIG.AWS_S3_SECRET_ACCESS_KEY,
        region: AWS_CONFIG.REGION
    });

    const [initialValues, setInitialValues] = useState({
        bank_statement: false,
        bank_statement_document: null,
        salary_slip: false,
        salary_slip_document: null,
        sold_assets_statement: false,
        sold_assets_statement_document: null,
        passport_id_statement: false,
        passport_id_statement_document: null
    });
    const [fileUploadInProgress, setFileUploadInProgress] = useState(false);
    const [fileName, setFileName] = useState(null);

    const handleClose = () => {
        setOpen(false);
    };

    const fileUpload = (e) => {
        setFileUploadInProgress(true);
        setFileName(e.target.name);

        const params = {
            Bucket: AWS_CONFIG.S3_BUCKET_NAME,
            Key: e.target.files[0].name,
            Body: e.target.files[0]
        };

        s3.upload(params).send((err, data) => {
            if (err) {
                console.log("There was an error uploading your file: ", err);
                setFileUploadInProgress(false);
                setFileName(null);
                return false;
            }

            setFileUploadInProgress(false);
            console.log("Successfully uploaded file.", data);
            const key = data?.key || data?.Key;

            setInitialValues({
                ...initialValues,
                [e.target.name + "_document"]: key
            });

            setFileName(null);
            return true;
        });
    };

    const handleChange = (e) => {
        setInitialValues({
            ...initialValues,
            [e.target.name]: e.target.checked
        });
    };

    const removeImage = (key) => {
        setInitialValues({
            ...initialValues,
            [key]: null
        });
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                {open && (
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize={true}
                        onSubmit={(values, { resetForm }) => {
                            if (values) {
                                const body = [];
                                const files = [
                                    {
                                        key: "salary_slip_document",
                                        type: REQUEST_DOCUMENT.salary_slip
                                    },
                                    {
                                        key: "bank_statement_document",
                                        type: REQUEST_DOCUMENT.bank_statement
                                    },
                                    {
                                        key: "sold_assets_statement_document",
                                        type: REQUEST_DOCUMENT.sold_assets_statement
                                    },
                                    {
                                        key: "passport_id_statement_document",
                                        type: REQUEST_DOCUMENT.passport_id
                                    }
                                ];

                                files.forEach((file) => {
                                    if (values[file.key] && values[file.key].length > 0) {
                                        body.push({
                                            document: values[file.key],
                                            document_type: file.type,
                                            user_id: userId
                                        });
                                    }
                                });

                                if (body.length > 0) {
                                    const slug = `en/admin/users/${encrypt(userId)}/documents`;
                                    addData(slug, body).finally(() => handleClose());
                                }
                            }
                            resetForm();
                        }}
                    >
                        {({ errors, touched, values, isValid, dirty }) => (
                            <Form>
                                <DialogContent>
                                    <Grid container width={400} spacing={GRID_SPACING}>
                                        {Object.keys(REQUEST_DOCUMENT).map((el, index) => (
                                            <Grid
                                                item
                                                key={index}
                                                xs={12}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "space-between"
                                                }}
                                            >
                                                <Grid item xs={8} sx={{ display: "flex" }}>
                                                    <Checkbox
                                                        value={values[el]}
                                                        id={el}
                                                        name={el}
                                                        color="primary"
                                                        sx={{ color: theme.palette.primary.main }}
                                                        onChange={handleChange}
                                                        error={touched[el] && Boolean(errors[el])}
                                                        helpertext={touched[el] && errors[el]}
                                                    />
                                                    <Typography sx={{ paddingTop: 1.2 }}>
                                                        {REQUEST_DOCUMENT[el]}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Button
                                                        variant="outlined"
                                                        component="label"
                                                        disabled={
                                                            (fileUploadInProgress && fileName === el.toLowerCase()) || !values[el]
                                                        }
                                                        fullWidth
                                                    >
                                                        {fileUploadInProgress &&
                            fileName === el.toLowerCase() ?
                                                            "Uploading..." :
                                                            `Upload File`}
                                                        <input
                                                            type="file"
                                                            id={el}
                                                            name={el}
                                                            onChange={fileUpload}
                                                            hidden
                                                        />
                                                    </Button>
                                                    {initialValues[el + "_document"] && (
                                                        <div className="remove-file">
                                                            <span>{initialValues[el + "_document"]}</span>
                                                            <DeleteIcon
                                                                sx={{ cursor: "pointer" }}
                                                                color="error"
                                                                onClick={() => removeImage(el + "_document")}
                                                            />
                                                        </div>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </DialogContent>
                                <DialogActions sx={{ pr: 2.5 }}>
                                    <Button
                                        sx={{ color: theme.palette.error.dark }}
                                        onClick={handleClose}
                                        color="secondary"
                                    >
                    Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        type="submit"
                                        disabled={
                                            !initialValues.bank_statement_document &&
                      !initialValues.passport_id_statement_document &&
                      !initialValues.salary_slip_document &&
                      !initialValues.sold_assets_statement_document
                                        }
                                    >
                    Request
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                )}
            </Dialog>
        </div>
    );
}

export default UploadDocument;
