export const GRID_SPACING = 2;
export const DRAWER_WIDTH = 260;
export const APP_DRAWER_WIDTH = 320;
export const HORIZONTAL_MAX_ITEM = 6;

export const BASE_PATH = "";
export const DASHBOARD_PATH = "/dashboard/default";

export const LAYOUT_CONST = {
  VERTICAL_LAYOUT: "vertical",
  HORIZONTAL_LAYOUT: "horizontal",
  DEFAULT_DRAWER: "default",
  MINI_DRAWER: "mini-drawer"
};

export const ROW_PER_PAGE_OPTIONS = [25, 50, 100];

export const ROW_PER_PAGE = 25;

export const PAYMENT_SCRUTINY_STATUS = {
  VERIFIED: "Verified",
  UNDER_VERIFICATION: "Under-Verification",
  UN_VERIFIED: "Un-Verified"
};

export const ORDER_STATUS = {
  PLACED: "Placed",
  FAILED: "Failed",
  READY_TO_DELIVER: "Ready To Deliver",
  REJECTED: "Rejected",
  DELIVERED: "Delivered",
  BLOCKED: "Blocked"
};

export const ORDER_STATUS_COLOR = {
  Placed: "primary",
  Failed: "error",
  "Ready To Deliver": "info",
  Rejected: "error",
  Delivered: "success",
  Blocked: "warning"
};

export const SOURCE_CURRENCY = {
  country_name: "Sweden",
  country_code: "SE",
  currency_code: "SEK",
  currency_name: "Swedish Krona",
  currency_symbol: "kr"
};

export const ROLES = {
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN: "ADMIN",
  USER: "USER"
};

export const GENDER = {
  MALE: "Male",
  FEMALE: "Female"
};

export const FEE_TYPE = {
  AMOUNT: "AMOUNT",
  PERCENTAGE: "PERCENTAGE"
};

export const DOCUMENT_TYPES = {
  SALARY_SLIP: "Salary Slip",
  BANK_STATEMENT: "Bank Statement",
  SOLD_ASSETS_STATEMENT: "Sold Assets Statement",
  passport_id: "Passport/ID"
};

export const CONFIRMATION_TYPES = {
  INSERT: "INSERT",
  PATCH: "PATCH",
  UPDATE: "UPDATE",
  DELETE: "DELETE"
};

export const ALARMING_LIMIT = 30000;

export const MOBILE_TRANSFER = "MMT";

export const BANK_TRANSFER = "Bank";

export const KENYA = "Kenya";

export const SOMALIA = "Somalia";

export const SOMALILAND = "Somaliland";

export const REJECTED_REASONS = [
  "Då vi inte kunnat uppnå tillräckligt god kännedom om din transaktion återbetalas pengarna.",
  "Då vi enbart förmedlar till privatpersoner fullföljs inte transaktion. Pengarnas betalas tillbaka till korten inom tre arbetsdagar. Du är välkommen tillbaka när du skickar pengar till en privat person.",
  "Vi kunde inte nå mottagaren.",
  "Pengarna kunde inte skickas till mottagaren."
];

export const VERIFY_RECIPIENT = [
  "The person does not match the names shown in the list",
  "The information that does not match is e.g. date of birth, place of birth, country of birth",
  "After talking to the customer, it turns out that the person on the list does not match the customer.",
  "After receiving passport/id, the person is not the same."
];

export const PARTNERS = {
  KeyRotationInterval: {
    Monthly: "MONTHLY",
    Quarterly: "QUARTERLY",
    Semi_Annually: "SEMI ANNUALLY",
    Annually: "ANNUALLY",
    Never: "NEVER"
  },

  ReminderEmailInterval: {
    One_Day_Before: "ONE DAY BEFORE",
    One_Week_Before: "ONE WEEK BEFORE",
    Fifteen_Days_Before: "FIFTEEN DAYS BEFORE",
    One_Month_Before: "ONE MONTH BEFORE",
    Never: "NEVER"
  },

  EmailSendingFrequency: {
    Daily: "DAILY",
    Weekly: "WEEKLY",
    Once: "ONCE",
    Never: "NEVER"
  }
};

export const HTTP_STATUS = ["POST", "GET", "PUT", "PATCH", "DELETE"];
export const HTTP_STATUS_CODE = [200, 400, 401, 403, 404, 417, 502];

export const REQUEST_DOCUMENT = {
  bank_statement: "Bank Statement",
  salary_slip: "Salary Slip",
  sold_assets_statement: "Sold Assets",
  passport_id: "Passport/ID"
};

export const AWS_CONFIG = {
  AWS_S3_ACCESS_KEY_ID: "AKIA2FALWQZ4L4VQ5HKN",
  AWS_S3_SECRET_ACCESS_KEY: "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",
  AWS_S3_URL: "https://malsom.s3.eu-north-1.amazonaws.com/",
  REGION: "eu-north-1",
  S3_ARN: "arn:aws:s3:::malsom",
  S3_BUCKET_NAME: "malsom"
};

export const SOCKET_ENUM = {
  ONLINE_STATUS_ROOM: 'online_status_room',
  USER_STATUS_EVENT: 'update_user_status',
  LOGOUT_EVENT: 'logout'
};

export const USER_SESSION_STATUS = {
  ONLINE: 'Online', // it means that users is online and active continuously doing optertions
  IDLE: 'Idle', // users is online but idle don't do anything right now and time for this is 5 mintues of inactivity
  AWAY: 'Away', // means user is online but right now away from screen
  OFFLINE: 'Offline' // it means that users is logged out
};
