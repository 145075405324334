import { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import { dispatch } from "store";
import {
  logoutEvent,
  disconnect,
  emitIdleState,
  emitActiveState,
  emitAwayState,
  keepIdle
} from "utils/socket";
import useAuth from "./useAuth";
import { socketConnection } from "store/accountReducer";
import { useNavigate } from "react-router-dom";
import { USER_SESSION_STATUS } from "constants";

const IDLE_TIMEOUT = 5 * 1000 * 60; // 5 minutes

export const useSocket = (namespace = "admins", isLoggedIn) => {
  const url = process.env.REACT_APP_API_URL || "http://localhost:9000";
  const socketUrl = url.endsWith("/api/") ? url.slice(0, -5) : url.endsWith("/api") ? url.slice(0, -4) : url;
  const SOCKET_SERVER_URL = `${socketUrl}${socketUrl.endsWith("/") ? "" : "/"}`;

  const { user, logout } = useAuth();
  const [socket, setSocket] = useState(null);

  const isIdle = useRef(false);
  const isAway = useRef(false);
  const socketRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Create a new connection
    if (isLoggedIn) {
      const newSocket = io(SOCKET_SERVER_URL + namespace, {
        withCredentials: true, // Set this if you have CORS policies
        auth: {
          token: localStorage.getItem("serviceToken"),
          DeviceType: "Portal"
        },
        transports: ["websocket"]
      });

      socketRef.current = newSocket;
      setSocket(newSocket);
      dispatch(socketConnection(newSocket));

      // socket connection
      newSocket.on("connect", (message) => {
        // console.log("Client socket connected");
      });

      // Optionally, handle socket connection errors
      newSocket.on("connect_error", (error) => {
        let errMessage = error?.message;
        try {
          errMessage = JSON.parse(errMessage);
        } catch (err) {
          console.log("Client socket connection error", err );
        }

        //  this is for checking if token is invalid or expired etc then login again
        const clearResources = (isAlReadyLoggedOut = true) => {
          if (!isAlReadyLoggedOut) {
            logoutEvent(newSocket, {
              sessionId: user?.session_id,
              userId: user?.user_id
            });
          }
        };

        if (["INVALID_SESSION", "INVALID_TOKEN"].includes(errMessage?.code)) {
          if (errMessage?.loggedOut === false) {
            dispatch(logout());
            localStorage.clear();
            navigate("/login");
            clearResources(false);
          } else {
            clearResources();
          }
        }

        console.error("Socket connection error:", errMessage);
      });
    }

    // Clean up connection on unmount
    return () => {
      if (socketRef.current) {
        setSocket(null);
        disconnect(socketRef.current);
      }
    };
  }, [namespace, isLoggedIn]);

  useEffect(() => {
    if (socket && user) {
      let inactivityTimer;
      const { session_id, user_id } = user;

      // Reset inactivity timer and emit idle/active events
      const resetInactivityTimer = () => {
        clearTimeout(inactivityTimer);

        if (isIdle.current || isAway.current) {
          emitActiveState(
            socket,
            user_id,
            session_id,
            window.location.pathname
          );
          isIdle.current = false;
          isAway.current = false;
        }

        inactivityTimer = setTimeout(() => {
          if (!isIdle.current) {
            isIdle.current = true;
            emitIdleState(socket, user_id, session_id);
          }
        }, IDLE_TIMEOUT);
      };

      // Handle visibility change (for away/active status)
      const handleVisibilityChange = () => {
        if (document.hidden && !isAway.current) {
          isAway.current = true;
          emitAwayState(socket, user_id, session_id);
        } else if (!document.hidden && isAway.current) {
          isAway.current = false;
          emitActiveState(
            socket,
            user_id,
            session_id,
            window.location.pathname
          );
        }
      };

      // Track focus/blur (away/active status)
      const handleWindowFocus = () => {
        if (isAway.current) {
          isAway.current = false;
          emitActiveState(
            socket,
            user_id,
            session_id,
            window.location.pathname
          );
        }
      };

      const handleWindowBlur = () => {
        if (!isAway.current) {
          isAway.current = true;
          emitAwayState(socket, user_id, session_id);
        }
      };

      const handleBeforeUnload = () => {
        keepIdle(socket, {
          state: USER_SESSION_STATUS.IDLE,
          userId: user_id,
          sessionId: session_id
        });
      };

      // Event listeners
      window.addEventListener("mousemove", resetInactivityTimer);
      window.addEventListener("keydown", resetInactivityTimer);
      window.addEventListener("visibilitychange", handleVisibilityChange);
      window.addEventListener("focus", handleWindowFocus);
      window.addEventListener("blur", handleWindowBlur);
      window.addEventListener("beforeunload", handleBeforeUnload);

      // Cleanup
      return () => {
        clearTimeout(inactivityTimer);
        window.removeEventListener("mousemove", resetInactivityTimer);
        window.removeEventListener("keydown", resetInactivityTimer);
        window.removeEventListener("visibilitychange", handleVisibilityChange);
        window.removeEventListener("focus", handleWindowFocus);
        window.removeEventListener("blur", handleWindowBlur);
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [socket, user]);

  return socket;
};
