import { LAYOUT_CONST } from "constants";

const config = {
    layout: LAYOUT_CONST.VERTICAL_LAYOUT, // vertical, horizontal
    drawerType: LAYOUT_CONST.DEFAULT_DRAWER, // default, mini-drawer
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: "light", // light, dark
    presetColor: "default",
    rtlLayout: false,
    container: false
};

export default config;
