import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../index";
import { getData } from "services";

const initialState = {
    error: null,
    allRecipientsData: [],
    blacklistRecipientsData: [],
    loading: false
};

const slice = createSlice({
    name: "recipients",
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        getSuccess(state, action) {
            state.allRecipientsData = action.payload;
        },

        getUnverifiedSuccess(state, action) {
            state.blacklistRecipientsData = action.payload;
        },

        hasLoading(state, action) {
            state.loading = action.payload;
        }
    }
});

export default slice.reducer;

export function getAllRecipientsData(query="") {
    return async () => {
        dispatch(slice.actions.hasLoading(true));
        try {
            const response = await getData("en/admin/user/recipients" + query);
            dispatch(slice.actions.getSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
        dispatch(slice.actions.hasLoading(false));
    };
}

export function getBlacklistRecipientsData(query="") {
    return async () => {
        dispatch(slice.actions.hasLoading(true));
        try {
            const response = await getData("en/admin/blacklist-recipient" + query);
            dispatch(slice.actions.getUnverifiedSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
        dispatch(slice.actions.hasLoading(false));
    };
}
