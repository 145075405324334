import { socketConnection } from "store/accountReducer";
import { dispatch } from "../store";
import { SOCKET_ENUM } from "constants";
import { USER_SESSION_STATUS } from "constants";

// Function to emit user idle state once
export const emitIdleState = (socket, userId, sessionId) => {
  emit(socket, SOCKET_ENUM.USER_STATUS_EVENT, { status: USER_SESSION_STATUS.IDEL, userId, sessionId });
};

// Function to emit user active state after being idle or away
export const emitActiveState = (socket, userId, sessionId, lastActivity) => {
  emit(socket, SOCKET_ENUM.USER_STATUS_EVENT, {
    status: USER_SESSION_STATUS.ONLINE,
    userId,
    sessionId,
    lastActivity,
  });
};

// Function to emit user away state once
export const emitAwayState = (socket, userId, sessionId) => {
  emit(socket, SOCKET_ENUM.USER_STATUS_EVENT, { status: USER_SESSION_STATUS.AWAY, userId, sessionId });
};

// events
export const logoutEvent = (socket, data) => {
  emit(socket, SOCKET_ENUM.LOGOUT_EVENT, data);
  disconnect(socket);
};

export const keepIdle = (socket, data) => {
  emit(socket, "keep-idle", data);
};

export const joinRoom = (socket, room) => {
  if (socket) {
    socket.emit("joinRoom", room);
  }
};

export const leaveRoom = (socket, room) => {
  if (socket) {
    socket.emit("leaveRoom", room);
  }
};

// Emit event to the server
export const emit = (socket, eventName, data) => {
  if (socket) {
    socket.emit(eventName, data);
  }
};

export const disconnect = (socket) => {
  if (socket) {
    socket.disconnect();
    dispatch(socketConnection(null));
  }
};

export const listenForUserStatus = (socket) => {
  let onlineUsers = null;

  if (socket) {
    const eventName = SOCKET_ENUM.LOGOUT_EVENT;

    socket.on(eventName, (data) => {
      onlineUsers = data;
    });
  }

  return onlineUsers;
}

export const listenForOnlineUsers = (socket) => {
  let onlineUsers = null;

  if (socket) {
    const eventName = SOCKET_ENUM.USER_STATUS_EVENT;

    socket.on(eventName, (data) => {
      onlineUsers = data;
    });
  }

  return onlineUsers;
}
