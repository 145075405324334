import { LOGIN, LOGOUT, SOCKET_CONNECTION } from "./actions";

const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    socket: null
};

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
    case LOGIN: {
        const { user } = action.payload;

        return {
            ...state,
            isLoggedIn: true,
            isInitialized: true,
            user
        };
    }
    case LOGOUT: {
        return {
            ...state,
            isInitialized: true,
            isLoggedIn: false,
            user: null
        };
    }
    case SOCKET_CONNECTION:
      return {
          ...state,
          socket: action.payload
    }
    default: {
        return { ...state };
    }
    }
};

export default accountReducer;

export function socketConnection(socket) {
  return (dispatch) => {
      dispatch(success(socket));
  };
  function success(socket) {
      return { type: SOCKET_CONNECTION, payload: socket };
  }
}