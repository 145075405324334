import { Box, CircularProgress, Typography } from "@mui/material";

const Loading = () => {
    return (
        <Box
            sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent overlay
                zIndex: 9999 // Ensure it’s on top
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "#eadbbe",
                    padding: "20px 50px",
                    border: "1px solid #bbbdbf",
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    // width: "300px", // Fixed width
                    maxHeight: "120px", // Max height to control vertical stretch
                    boxSizing: "border-box",
                    textAlign: "center"
                }}
            >
                <Typography sx={{ marginBottom: 2, color: "#000", fontWeight: "500" }}>
                    Loading...
                </Typography>
                <CircularProgress />
            </Box>
        </Box>
    );
};

export default Loading;
