import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow
} from "@mui/material";
import styled from "@emotion/styled";
import { DATETIME_FORMAT } from "services/format";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: "0px 8px",
    border: "1px solid #dee2e6",
    fontSize: "0.845rem",
    cursor: "default",
    paddingTop: "8px",
    paddingBottom: "8px"
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
    padding: "10px 10px",
    fontWeight: "bold",
    border: "1px solid #dee2e6",
    fontSize: "0.845rem",
    cursor: "default",
    minWidth: 150,
    backgroundColor: theme.palette.action.hover,
    height: 40
}));

const MessageHistoryLog = ({ open, setOpen, data = {} }) => {
    return (
        <Dialog
            open={open}
            onClose={setOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ minWidth: 900 }}
        >
            {open && (
                <div style={{ padding: "0 10px" }}>
                    <DialogContent>
                        <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Grid item>
                                <h2 style={{ margin: 0 }}>{`${data.first_name || ""} ${data.last_name || ""}`}</h2>
                            </Grid>
                            <Grid item>
                                <div>
                                    <b>Message Type : </b>
                                    <span className={`sch ${data?.message_type?.split(' ').join('_').toLowerCase()}`}>{data.message_type}</span>
                                </div>
                                <div style={{ marginTop: "5px" }}>
                                    <b> {data?.message_type} For : </b>
                                    <span className="underline">{data?.action_triggered}</span>
                                </div>
                            </Grid>
                        </Grid>
                        <TableContainer sx={{ minWidth: 500, marginTop: 2 }}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <StyledTableHeaderCell>Date</StyledTableHeaderCell>
                                        <StyledTableHeaderCell>Status</StyledTableHeaderCell>
                                        <StyledTableHeaderCell>Remarks</StyledTableHeaderCell>
                                    </TableRow>

                                    {data?.MessageLogs?.map((el, index) => (
                                        <TableRow key={index}>
                                            <StyledTableCell>{DATETIME_FORMAT(el.updated_at)}</StyledTableCell>
                                            <StyledTableCell>
                                                <span className={`sch ${el?.status?.split(' ').join('_').toLowerCase()}`}>{el.status}</span>
                                            </StyledTableCell>
                                            <StyledTableCell>{el.reason}</StyledTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions sx={{ pr: 2.5 }}>
                        <Button
                            onClick={setOpen}
                            color="secondary"
                            variant="contained"
                        >
                Close
                        </Button>
                    </DialogActions>
                </div>
            )}
        </Dialog>
    );
}

export default MessageHistoryLog;
