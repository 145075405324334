import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../index";
import { getData } from "services";

const initialState = {
  error: null,
  allCustomerData: [],
  unverifiedCustomerData: [],
  blockedCustomerData: [],
  verificationHistoryData: [],
  userCoutryData: [],
  userOrderData: [],
  userComplianceHistoryData: [],
  complianceHistoryData: [],
  recipientComplianceData: [],
  recipientComplianceHistoryData: [],
  userDocumentsData: [],
  userRecipientsData: [],
  loading: false
};

const slice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },

    getSuccess(state, action) {
      state.allCustomerData = action.payload;
    },

    getUnverifiedSuccess(state, action) {
      state.unverifiedCustomerData = action.payload;
    },

    getVerificationSuccess(state, action) {
      state.verificationHistoryData = action.payload;
    },
    getBlockedSuccess(state, action) {
      state.blockedCustomerData = action.payload;
    },

    getUserCountrySuccess(state, action) {
      state.userCoutryData = action.payload;
    },

    getUserOrderSuccess(state, action) {
      state.userOrderData = action.payload;
    },

    getComplianceHistorySuccess(state, action) {
      state.complianceHistoryData = action.payload;
    },

    getUserComplianceHistorySuccess(state, action) {
      state.userComplianceHistoryData = action.payload;
    },

    getRecipientComplianceSuccess(state, action) {
      state.recipientComplianceData = action.payload;
    },
    getRecipientComplianceHistorySuccess(state, action) {
      state.recipientComplianceHistoryData = action.payload;
    },

    getUserDocumentsSuccess(state, action) {
      state.userDocumentsData = action.payload;
    },

    getUserRecipientsSuccess(state, action) {
      state.userRecipientsData = action.payload;
    },

    hasLoading(state, action) {
      state.loading = action.payload;
    }
  }
});

export default slice.reducer;

export function getAllCustomerData(query = "") {
  return async () => {
    dispatch(slice.actions.hasLoading(true));
    try {
      const response = await getData("en/admin/users" + query);
      dispatch(slice.actions.getSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    dispatch(slice.actions.hasLoading(false));
  };
}

export function getUnverifiedCustomerData(query = "") {
  return async () => {
    dispatch(slice.actions.hasLoading(true));
    try {
      const response = await getData("en/admin/users/un-verified" + query);
      dispatch(slice.actions.getUnverifiedSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    dispatch(slice.actions.hasLoading(false));
  };
}

export function getVerificationHistory(query = "") {
  return async () => {
    dispatch(slice.actions.hasLoading(true));
    try {
      const response = await getData("en/admin/users/verification-history" + query);
      dispatch(slice.actions.getVerificationSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    dispatch(slice.actions.hasLoading(false));
  };
}

export function getBlockedCustomerData(query = "") {
  return async () => {
    dispatch(slice.actions.hasLoading(true));
    try {
      const response = await getData("en/admin/users-blocked" + query);
      dispatch(slice.actions.getBlockedSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    dispatch(slice.actions.hasLoading(false));
  };
}

export function getUserCountries(user_id = "", query = "") {
  return async () => {
    dispatch(slice.actions.hasLoading(true));
    try {
      const response = await getData("en/admin/user_countries/" + user_id + query);
      dispatch(slice.actions.getUserCountrySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    dispatch(slice.actions.hasLoading(false));
  };
}

export function getUserOrders(query = "") {
  return async () => {
    dispatch(slice.actions.hasLoading(true));
    try {
      const response = await getData("en/admin/orders" + query);
      dispatch(slice.actions.getUserOrderSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    dispatch(slice.actions.hasLoading(false));
  };
}

export function getUserComplianceHistory(query = "") {
  return async () => {
    dispatch(slice.actions.hasLoading(true));
    try {
      const response = await getData("en/admin/user-compliance-history" + query);
      dispatch(slice.actions.getUserComplianceHistorySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    dispatch(slice.actions.hasLoading(false));
  };
}

export function getComplianceHistory(query = "") {
  return async () => {
    dispatch(slice.actions.hasLoading(true));
    try {
      const response = await getData("en/admin/compliance-history" + query);
      dispatch(slice.actions.getComplianceHistorySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    dispatch(slice.actions.hasLoading(false));
  };
}

export function getRecipientCompliance(query = "") {
  return async () => {
    dispatch(slice.actions.hasLoading(true));
    try {
      const response = await getData("en/admin/recipient-compliance" + query);
      dispatch(slice.actions.getRecipientComplianceSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    dispatch(slice.actions.hasLoading(false));
  };
}

export function getRecipientComplianceHistory(query = "") {
  return async () => {
    dispatch(slice.actions.hasLoading(true));
    try {
      const response = await getData("en/admin/recipient-compliance-history" + query);
      dispatch(slice.actions.getRecipientComplianceHistorySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    dispatch(slice.actions.hasLoading(false));
  };
}

export function getUserDocuments(userId = "", query = "") {
  return async () => {
    dispatch(slice.actions.hasLoading(true));
    try {
      const response = await getData("en/admin/users/" + userId + "/documents" + query);
      dispatch(slice.actions.getUserDocumentsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    dispatch(slice.actions.hasLoading(false));
  };
}

export function getUserRecipients(userId = "", query = "") {
  return async () => {
    dispatch(slice.actions.hasLoading(true));
    try {
      const response = await getData("en/admin/user/" + userId + "/recipients" + query);
      dispatch(slice.actions.getUserRecipientsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    dispatch(slice.actions.hasLoading(false));
  };
}
