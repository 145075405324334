// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": `#ffffff`,
	"primaryLight": `#ffebc9`,
	"primary200": `#ffdfb5`,
	"primaryMain": `#ef7304`,
	"primaryDark": `#c35800`,
	"primary800": `#b24e00`,
	"secondaryLight": `#fdf1e6`,
	"secondary200": `#ffdfb5`,
	"secondaryMain": `#ef7304`,
	"secondaryDark": `#c35800`,
	"secondary800": `#b24e00`,
	"successLight": `#b9f6ca`,
	"success200": `#69f0ae`,
	"successMain": `#00e676`,
	"successDark": `#00c853`,
	"errorLight": `#ef9a9a`,
	"errorMain": `#f44336`,
	"errorDark": `#c62828`,
	"orangeLight": `#fbe9e7`,
	"orangeMain": `#ffab91`,
	"orangeDark": `#d84315`,
	"warningLight": `#fff8e1`,
	"warningMain": `#ffe57f`,
	"warningDark": `#ffc107`,
	"grey50": `#f8fafc`,
	"grey100": `#eef2f6`,
	"grey200": `#e3e8ef`,
	"grey300": `#cdd5df`,
	"grey500": `#697586`,
	"grey600": `#4b5565`,
	"grey700": `#364152`,
	"grey900": `#121926`,
	"darkPaper": `#111936`,
	"darkBackground": `#1a223f`,
	"darkLevel1": `#29314f`,
	"darkLevel2": `#212946`,
	"darkTextTitle": `#ffffff`,
	"darkTextPrimary": `#c6c6c6`,
	"darkTextSecondary": `#7e7e7e`,
	"darkPrimaryLight": `#ffebc9`,
	"darkPrimaryMain": `#ef7304`,
	"darkPrimaryDark": `#c35800`,
	"darkPrimary200": `#ffdfb5`,
	"darkPrimary800": `#b24e00`,
	"darkSecondaryLight": `#fdf1e6`,
	"darkSecondaryMain": `#ef7304`,
	"darkSecondaryDark": `#c35800`,
	"darkSecondary200": `#ffdfb5`,
	"darkSecondary800": `#b24e00`
};
export default ___CSS_LOADER_EXPORT___;
