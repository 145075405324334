import React, { useState, useEffect } from 'react';
import { useTheme } from "@mui/material/styles";
import {
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography
} from "@mui/material";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { ROW_PER_PAGE_OPTIONS, PAYMENT_SCRUTINY_STATUS } from "constants";
import { styled } from "@mui/material/styles";
import Loading from "components/Loading";
import { formatCurrency } from 'services/common';
import { Box } from '@mui/system';

// Styled components
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    "&:last-of-type td, &:last-of-type th": {
        border: 0
    },
    // Reduce row height
    height: 40
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: "0px 6px", // Reduced padding for cells
    border: "1px solid #dee2e6",
    fontSize: "0.845rem", // Font size for table cells
    cursor: "default" // Default cursor
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
    padding: "10px 6px", // Increased padding for header cells
    border: "1px solid #bbbdbf",
    fontSize: "1rem", // Larger font size for header cells
    fontWeight: "bold", // Bold text for header
    backgroundColor: "#eadbbe"
}));

const StyledSummaryRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.grey[200],
    fontWeight: 'bold'
}));

// Main component
export const TableData = ({
    rows = {},
    headCells,
    actions = [],
    handleClose,
    onSort,
    loading = false,
    page,
    rowsPerPage,
    onPageChange
}) => {
    const count = rows ? rows.totalRecords || 0 : 0;
    const items = rows ? rows.items || [] : [];
    const theme = useTheme();

    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [data, setData] = useState();

    // Calculate the totals
    const totalAmountToPay = items.reduce((sum, row) => sum + (parseFloat(row.total_amount_to_pay) || 0), 0);
    const totalAmountToReceive = items.reduce((sum, row) => sum + (parseFloat(row.receiving_amount) || 0), 0);

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        onSort(property, order);
    };

    const handleMenuClick = (event, data = null) => {
        setData(data);
        setAnchorEl(event?.currentTarget);
    };

    const handleCloseMenu = (event) => {
        if (anchorEl && anchorEl.contains(event.target)) return;
        setAnchorEl(null);
        handleClose(event, data);
    };

    return (
        <>
            {loading && (
                <Loading />
            )}
            <TableContainer sx={{ minWidth: 350, margin: "0 15px", width: "100%", overflowX: "auto", maxHeight: window.location.pathname === "/orders" ? "74vh" : "85vh" }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow style={{ whiteSpace: "nowrap" }}>
                            {headCells.map((headCell) => (
                                <StyledTableHeaderCell
                                    key={headCell.id}
                                    align={headCell.align}
                                    padding={headCell.disablePadding ? "none" : "normal"}
                                    sortDirection={orderBy === headCell.id ? order : false}
                                >
                                    {headCell.sortable ? (
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : "asc"}
                                            onClick={() => handleRequestSort(headCell.id)}
                                        >
                                            {headCell.label}
                                            {orderBy === headCell.id ? (
                                                <Typography component="span" sx={{ display: "none" }}>
                                                    {order === "desc" ?
                                                        "sorted descending" :
                                                        "sorted ascending"}
                                                </Typography>
                                            ) : null}
                                        </TableSortLabel>
                                    ) : (
                                        headCell.label
                                    )}
                                </StyledTableHeaderCell>
                            ))}

                            {actions.length > 0 && (
                                <StyledTableHeaderCell align="center" sx={{ pr: 3, width: 10 }}>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            color: theme.palette.mode === "dark" ? theme.palette.grey[600] : "grey.900"
                                        }}
                                    >
                                        Action
                                    </Typography>
                                </StyledTableHeaderCell>
                            )}
                        </TableRow>
                    </TableHead>

                    {/* data */}
                    <TableBody style={{ whiteSpace: "nowrap" }}>
                        {items.map((row, index) => {
                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            if (typeof row === "number") return null;
                            return (
                                <StyledTableRow hover key={index}>
                                    {headCells.map((el, ind) => (
                                        <StyledTableCell
                                            component="th"
                                            id={index}
                                            key={ind}
                                            scope="row"
                                            align={el.align}
                                            sx={{ cursor: el.isLink ? "pointer" : "default" }} // Change cursor based on link status
                                            dangerouslySetInnerHTML={{
                                                __html: el.format ?
                                                    el.format(row[el.id], row) :
                                                    row[el.id]
                                            }}
                                            className={
                                                row.order_id ?
                                                    row?.is_sanction || row?.is_pep || row?.is_rca ?
                                                        "compliance-user" :
                                                        [
                                                            PAYMENT_SCRUTINY_STATUS.UNDER_VERIFICATION
                                                        ].includes(row?.payment_scrutiny_status) ?
                                                            "unverified-orders" :
                                                            "" :
                                                    ""
                                            }
                                            onClick={() => el?.cellClickAction && el.cellClickAction(row)}
                                        />
                                    ))}

                                    {actions.length > 0 && (
                                        <StyledTableCell align="center" sx={{ pr: 3 }}>
                                            <IconButton
                                                onClick={(e) => handleMenuClick(e, row)}
                                                size="large"
                                                aria-label="more options"
                                                sx={{ padding: 0 }}
                                            >
                                                <MoreHorizOutlinedIcon
                                                    fontSize="small"
                                                    aria-controls={`menu-popular-card-${index}`}
                                                    aria-haspopup="true"
                                                    sx={{ color: "grey.500" }}
                                                />
                                            </IconButton>
                                            <Menu
                                                id={`menu-popular-card-${index}`}
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleCloseMenu}
                                                variant="selectedMenu"
                                                anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "right"
                                                }}
                                                transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "right"
                                                }}
                                                sx={{
                                                    "& .MuiMenu-paper": {
                                                        boxShadow: theme.customShadows.z1
                                                    }
                                                }}
                                            >
                                                {actions.map((el, ind) => {
                                                    const shouldShowMenuItem = data && (!el.hasOwnProperty("condition") || el.condition(data));

                                                    return shouldShowMenuItem ? (
                                                        <Box key={ind}>
                                                            <MenuItem
                                                                sx={{ paddingTop: 0, paddingBottom: 0 }}
                                                                onClick={() => handleCloseMenu(el)}
                                                            >
                                                                {el.icon}
                                                                <Typography style={{ marginLeft: "10px", fontSize: '12px' }}>
                                                                    {el.toolTip}
                                                                </Typography>
                                                            </MenuItem>
                                                            {ind != actions.length - 1 && (<hr style={{ margin: "5px 0", color: 'rgba(251, 252, 253, 1)', opacity: '0.5' }} />)}
                                                        </Box>
                                                    ) : null;
                                                })}
                                            </Menu>
                                        </StyledTableCell>
                                    )}
                                </StyledTableRow>
                            );
                        })}
                        {items.length === 0 && (
                            <TableRow>
                                <StyledTableCell
                                    colSpan={headCells.length + (actions.length > 0 ? 1 : 0)}
                                >
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            textAlign: "center",
                                            fontWeight: 400,
                                            fontSize: "16px"
                                        }}
                                    >
                                        No Record Found
                                    </Typography>
                                </StyledTableCell>
                            </TableRow>
                        )}
                        {/* Summary Row */}
                        {items.length > 0 && (
                            <StyledSummaryRow>
                                {headCells.map((headCell, index) => (
                                    <StyledTableCell key={index} align={headCell.align}>
                                        {headCell.id === 'total_amount_to_pay' ? (
                                            <Typography variant="subtitle1">
                                                {formatCurrency(totalAmountToPay.toFixed(2))} SEK
                                            </Typography>
                                        ) : headCell.id === 'receiving_amount' ? (
                                            <Typography variant="subtitle1">
                                                {totalAmountToReceive.toFixed(2)} USD
                                            </Typography>
                                        ) : (
                                            ""
                                        )}
                                    </StyledTableCell>
                                ))}
                                <StyledTableCell colSpan={actions.length > 0 ? 1 : 0} />
                            </StyledSummaryRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => onPageChange(newPage, rowsPerPage)}
                onRowsPerPageChange={(event) => onPageChange(page, parseInt(event.target.value, 10))}
            />
        </>
    );
};

export default TableData;
