import { formatCurrency } from "./common";
import { SOURCE_CURRENCY, ROLES } from "constants";
import { format } from "date-fns";
import { TZDate } from "@date-fns/tz";

const toTitleCase = (str) => str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')

export const ORDER_NO_FORMAT = (v, r, c) => {
    const valueToDisplay = v || r.order_id;
    const formattedValue = valueToDisplay?.toString().padStart(6, "0");
    return `<a class="cursor-pointer underline">${formattedValue}</a>`;
};

export const ORDER_STATUS_FORMAT = (v, r, c) => `<span class=order_status>
  <span class=${v.split(" ").join("-").toLowerCase()}>${v} </span>
</span>`;

export const LINK_FORMAT = (v, r, c) =>
    `<a class="cursor-pointer underline">${v}</a>`;

export const RECIPIENT_NAME_FORMAT = (v, r, c) =>
    `<span class=${
        r.is_recipient_complaince ? "recipient-compliance" : ""
    }>${v}</span>`;

export const DES_AMOUNT_FORMAT = (v, r, c) =>
    `${v} ${r.destination_currency_code}`;

export const SOU_AMOUNT_FORMAT = (v, r, c) => `${v} ${r.source_currency_code}`;

export const NUMBER_FORMAT = (v, r, c) => v.toLocaleString();

export const CURRENCY_FORMAT = (v, r, c) =>
    v == null ?
        `0 ${SOURCE_CURRENCY.currency_code}` :
        `${formatCurrency(v.toFixed(2))} ${SOURCE_CURRENCY.currency_code}`;

export const DECIMAL_FORMAT_2 = (v, r, c) =>
    v == null ? "0.00" : v.toFixed(2);

export const DECIMAL_FORMAT_1 = (v, r, c) => (v == null ? "N/A" : v.toFixed(1));

export const BOOLEAN_FORMAT = (v, r, c) => (v == null || v <= 0 ? "No" : "Yes");

export const PERCENT_FORMAT = (v, r, c) => (v == null ? "N/A" : v + "%");

// Convert a UTC date to the specified time zone and format it
export const DATETIME_FORMAT = (v, r, c) => {
    if (v == null) return "";

    try {
      // Create a new TZDate instance from the UTC date string
      const tzDate = new TZDate(v, "Europe/Stockholm"); // Adjust to your target time zone

      return format(tzDate, "yyyy-MM-dd HH:mm");
    } catch(err) {
      return ""
    }

};

export const DATE_FORMAT = (v, r, c) =>
    v == null ? "" : format(new Date(v), "yyyy-MM-dd");

export const TIME_FORMAT = (v, r, c) =>
    v == null ? "" : format(new Date(v), "HH:mm");

export const IMAGE_FORMAT = (v, r, c) =>
    v == null || v === "" ?
        "N/A" :
        `<img alt="image" title="Image" width="50" src="${v}" class="col-small-image">`;

export const PERSON_IMAGE_FORMAT = (v, r, c) =>
    v == null || v === "" ?
        '<img title="No Image" src="/assets/images/avatar.jpg" class="col-small-image">' :
        '<img alt="image" title="Click to view" src="' +
      v +
      '" class="col-small-image">';

export const CAPTIALIZE_FORMAT = (v, r, c) => {
    return v ? `${v[0].toUpperCase()}${v.slice(1).toLowerCase()}` : "";
};

export const API_STATUS_FORMAT = (v, r, c) => {
    const cl = v ? "success" : "fail";
    return `<span class="api-status"><span class="${cl}">${
        v ? "Success" : "Failure"
    }</span></span>`;
};

export const HTTP_REQUEST = (v, r, c) => {
    const { http_method, response_code, api_endpoint } = r;
    const codeCl =
    response_code >= 200 && response_code < 300 ?
        "2xx" :
        response_code >= 300 && response_code < 400 ?
            "3xx" :
            response_code >= 400 && response_code < 500 ?
                "4xx" :
                "5xx";
    return `
      <div class="http-request">
          <span class="status-${codeCl}">${response_code}</span>
          <span class="${http_method?.toLowerCase()}">${http_method}</span>
          <span >${api_endpoint}</span>
      </div>
  `;
};

export const PERFORMED_BY = (v, r, c) => {
    return r?.by_whom ?
        `<b>${r.first_name || ''} ${r.last_name || ''}</b>` :
        '<b>System</b>';
};

export const ONINE_STATUS_FORMAT = (v, r, c) => {
    const value = r.is_portal_login ? "Online" : "Offline";
    return `<span class="${value.toLowerCase()}">${value}</span>`;
};

export const P_ONINE_STATUS_FORMAT = (v, r, c) => {
    const value = r.is_login ? "Online" : "Offline";
    return `<span class="${value.toLowerCase()}">${value}</span>`;
};

export const NEW_DOCS_FORMAT = (v, r, c) => {
    return `<span class="docs-count">${v}</span>`;
};

export const COMPLIANCE_STATUS = (v, r, c) => {
    const status = v ? "Pending Compliance" : "Compliant";
    const cl = v ? "rejected" : "delivered";
    return `<span class='order_status'> <span class='${cl}'>${status} </span></span>`;
};

export const SENDER_NAME_FORMAT = (
    v,
    r,
    c
) => `<a class="order_no cursor-pointer underline">
  <span class=${
    r.is_sender_complaince ? "recipient-compliance" : ""
}>${v}</span>
</a>`;

export const TAAJ_STATUS_FORMAT = (v, r, c) => {
    const status = v ? "Låst" : "Aktiv";
    const cl = v ? "rejected" : "delivered";
    return `<span class='order_status'> <span class='${cl}'>${status} </span></span>`;
};

export const NETWORK_TAGS_FORMAT = (v, r, c) => {
    let tags = "";
    if (v && v?.length) {
        v.forEach(
            (tag) => (tags = `${tags} <span class="network-tags">${tag}</span>`)
        );
    }
    return tags;
};

export const PERCENTAGE_FORMAT = (v) => {
  const cl = v < 0 ? 'red' : 'green';
  return `<span class="${cl}">${v} %</span>`
}

export const FULL_NAME_FORMAT = (v, r, c) => `<span>${r?.first_name} ${r?.last_name}</span>`;

export const LOG_DETAIL_FORMAT = (v, r, c) => `<a class="order_no underline cursor-pointer">${v}</a>`

export const LOG_STATUS_FORMAT = (v, r, c) => {
  return `<span class="app-scheduled"><span class="sch ${v.split(' ').join('_').toLowerCase()}">${v}</span></span>`;
}

export const ONLINE_STATUS_FORMAT = (v, r, c) => {
  const value = r.is_session_active ? 'Logged In' : 'Logged Out';
  return `<span class="${value.toLowerCase().split(' ').join('_')}">${value}</span>`;
};

export const ACTIVITY_STATUS_FORMAT = (v, r, c) => {
  return `<span class="${v.toLowerCase()}">${v}</span>`;
};

export const PLATFORM_STATUS = (v, r, c) => {
  const pt = v?.toLowerCase();
  const ic = pt === 'portal' ? 'fa-building' : (pt === 'website' ? 'fa-globe' : (pt === 'ios' ? 'fa-apple' : 'fa-android'));
  return `
      <span>
          <i class="fas ${ic} ${pt}"></i>
          <span>${toTitleCase(v)}</span>
      </span>
  `;
}

export const ROLE_STATUS = (v, r, c) => {
  const ic = v === ROLES.ADMIN ? 'fa-user-cog' : (v === ROLES.SUPER_ADMIN ? 'fa-user-shield' : 'fa-users')
  return `
      <span>
          <i class="fas ${ic}"></i>
          <span class="${v?.toLowerCase()}">${toTitleCase(v === ROLES.USER ? 'CUSTOMER' : v?.split('_').join(' '))}</span>
      </span>
  `;
}

export const TIME_AGO_FORMAT = (v, r, c) => {
  if (!v) {
      return 'N/A';
  }

  const now = new Date().getTime();
  const lastActive = new Date(v).getTime();
  // Calculate the difference in milliseconds
  const diffInMs = now - lastActive;

  // Define time constants
  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;
  const month = day * 30.44;  // Average days in a month
  const year = day * 365.25;   // Average days in a year accounting for leap years

  let fomated = "Just now";
  if (diffInMs >= year) {
      const years = Math.floor(diffInMs / year);
      fomated = years === 1 ? "1 year ago" : `${years} years ago`;
  } else if (diffInMs >= month) {
      const months = Math.floor(diffInMs / month);
      fomated = months === 1 ? "1 month ago" : `${months} months ago`;
  } else if (diffInMs >= day) {
      const days = Math.floor(diffInMs / day);
      fomated = days === 1 ? "1 day ago" : `${days} days ago`;
  } else if (diffInMs >= hour) {
      const hours = Math.floor(diffInMs / hour);
      fomated = hours === 1 ? "1 hour ago" : `${hours} hours ago`;
  } else if (diffInMs >= minute) {
      const minutes = Math.floor(diffInMs / minute);
      fomated = minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
  } else {
      fomated = "Just now";
  }
  return `<b>${fomated}</b>`;
}