import Routes from "routes";
import NavigationScroll from "layout/NavigationScroll";
import RTLLayout from "components/RTLLayout";
import Snackbar from "components/Extended/Snackbar";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ThemeCustomization from "themes";
import "./App.css";
import { JWTProvider as AuthProvider } from "contexts/JWTContext";

// Suppress specific error by matching the message
const originalError = console.error;
(console.error = (...args) => {
  if (args.length > 0 && args[0].includes('Warning: validateDOMNesting')) { return }
  originalError(...args);
})();

const App = () => {
  return (
    <ThemeCustomization>
      <RTLLayout>
        <NavigationScroll>
          <AuthProvider>
            <Routes />
            <Snackbar />
            <ToastContainer />
          </AuthProvider>
        </NavigationScroll>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
