import { GRID_SPACING, GENDER } from "constants";
import React, { useEffect, useState } from "react";
import {
    Button,
    Checkbox,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { updateData } from "services";
import { useFormik } from "formik";

const AddEditCustomer = ({
    fields = [],
    validationSchema,
    url,
    paymentPurposeOptions=[],
    paymentSourceOptions=[],
    countriesOptions=[],
    onClose=() =>{}
}) => {
    const theme = useTheme();

    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        if (fields.length > 0) {
            let obj = {};
            fields.forEach((el) => (obj[el.id] = el.prefix ? el.value?.replace(el.prefix, "") : el.value));
            setInitialValues(obj);
        } else {
            setInitialValues({});
        }
    }, [fields]);

    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values) => {
            if (values.mobile_no) { values.mobile_no = "+46" + values.mobile_no }
            values.countriesToSend = values.country_id;
            delete values.country_id;
            delete values.user_no;
            await updateData(url, values);
            values.mobile_no = values.mobile_no.replace("+46", "");
            onClose();
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid sx={{ padding: "20px" }} container spacing={GRID_SPACING}>
                <Grid item md={8}>
                    <Typography variant="h2" marginBottom={2}>Identity</Typography>
                    <Grid container spacing={GRID_SPACING}>
                        <Grid sx={{ margin: "5px 0" }} item xs={12} md={6}>
                            <TextField
                                fullWidth
                                type="text"
                                id="user_no"
                                name="user_no"
                                label="User No"
                                value={formik.values["user_no"] || ""}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched["user_no"] && Boolean(formik.errors["user_no"])}
                                helpertext={formik.touched["user_no"] && formik.errors["user_no"]}
                                disabled={true}
                            />
                            {formik.errors["user_no"] && formik.touched["user_no"] ? (
                                <div className="error">{formik.errors["user_no"]}*</div>
                            ) : null}
                        </Grid>

                        <Grid sx={{ margin: "5px 0" }} item xs={12} md={6}>
                            <TextField
                                fullWidth
                                type="text"
                                id="personal_number"
                                name="personal_number"
                                label="Personal No"
                                value={formik.values["personal_number"] || ""}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched["personal_number"] && Boolean(formik.errors["personal_number"])}
                                helpertext={formik.touched["personal_number"] && formik.errors["personal_number"]}
                                disabled={true}
                            />
                            {formik.errors["personal_number"] && formik.touched["personal_number"] ? (
                                <div className="error">{formik.errors["personal_number"]}*</div>
                            ) : null}
                        </Grid>

                        <Grid sx={{ margin: "5px 0" }} item xs={12} md={6}>
                            <TextField
                                fullWidth
                                type="text"
                                id="first_name"
                                name="first_name"
                                label="First Name"
                                value={formik.values["first_name"] || ""}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched["first_name"] && Boolean(formik.errors["first_name"])}
                                helpertext={formik.touched["first_name"] && formik.errors["first_name"]}
                            />
                            {formik.errors["first_name"] && formik.touched["first_name"] ? (
                                <div className="error">{formik.errors["first_name"]}*</div>
                            ) : null}
                        </Grid>

                        <Grid sx={{ margin: "5px 0" }} item xs={12} md={6}>
                            <TextField
                                fullWidth
                                type="text"
                                id="last_name"
                                name="last_name"
                                label="Last Name"
                                value={formik.values["last_name"] || ""}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched["last_name"] && Boolean(formik.errors["last_name"])}
                                helpertext={formik.touched["last_name"] && formik.errors["last_name"]}
                            />
                            {formik.errors["last_name"] && formik.touched["last_name"] ? (
                                <div className="error">{formik.errors["last_name"]}*</div>
                            ) : null}
                        </Grid>

                        <Grid sx={{ margin: "5px 0" }} item xs={12} md={6}>
                            <TextField
                                fullWidth
                                type="email"
                                id="email"
                                name="email"
                                label="Email"
                                value={formik.values["email"] || ""}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched["email"] && Boolean(formik.errors["email"])}
                                helpertext={formik.touched["email"] && formik.errors["email"]}
                                disabled={true}
                            />
                            {formik.errors["email"] && formik.touched["email"] ? (
                                <div className="error">{formik.errors["email"]}*</div>
                            ) : null}
                        </Grid>

                        <Grid sx={{ margin: "5px 0" }} item xs={12} md={6}>
                            <TextField
                                fullWidth
                                type="text"
                                id="mobile_no"
                                name="mobile_no"
                                label="Mobile"
                                value={formik.values["mobile_no"] || ""}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched["mobile_no"] && Boolean(formik.errors["mobile_no"])}
                                helpertext={formik.touched["mobile_no"] && formik.errors["mobile_no"]}
                                InputProps={{
                                    startAdornment: <InputAdornment position='start'>+46</InputAdornment>
                                }}
                            />
                            {formik.errors["mobile_no"] && formik.touched["mobile_no"] ? (
                                <div className="error">{formik.errors["mobile_no"]}*</div>
                            ) : null}
                        </Grid>

                        <Grid sx={{ margin: "5px 0" }} item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel id="gender">Gender</InputLabel>
                                <Select
                                    id="gender"
                                    name="gender"
                                    label="Gender"
                                    defaultValue={formik.values["gender"]}
                                    value={formik.values["gender"] || ""}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched["gender"] && Boolean(formik.errors["gender"])}
                                    helpertext={formik.touched["gender"] && formik.errors["gender"]}
                                >
                                    {Object.values(GENDER)?.map((option, index) => (
                                        <MenuItem key={index} value={option}>{option}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid sx={{ margin: "5px 0" }} item xs={12} md={6}>
                            <TextField
                                fullWidth
                                type="text"
                                id="pob"
                                name="pob"
                                label="Place of Birth"
                                value={formik.values["pob"] || ""}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched["pob"] && Boolean(formik.errors["pob"])}
                                helpertext={formik.touched["pob"] && formik.errors["pob"]}
                            />
                            {formik.errors["pob"] && formik.touched["pob"] ? (
                                <div className="error">{formik.errors["pob"]}*</div>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>


                <Grid item md={4}>
                    <Typography variant="h2" marginBottom={2}>Address</Typography>
                    <Grid container spacing={GRID_SPACING}>
                        <Grid sx={{ margin: "5px 0" }} item xs={12}>
                            <TextField
                                fullWidth
                                type="text"
                                id="street"
                                name="street"
                                label="Street"
                                value={formik.values["street"] || ""}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched["street"] && Boolean(formik.errors["street"])}
                                helpertext={formik.touched["street"] && formik.errors["street"]}
                            />
                            {formik.errors["street"] && formik.touched["street"] ? (
                                <div className="error">{formik.errors["street"]}*</div>
                            ) : null}
                        </Grid>

                        <Grid sx={{ margin: "5px 0" }} item xs={12}>
                            <TextField
                                fullWidth
                                type="text"
                                id="city"
                                name="city"
                                label="City"
                                value={formik.values["city"] || ""}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched["city"] && Boolean(formik.errors["city"])}
                                helpertext={formik.touched["city"] && formik.errors["city"]}
                            />
                            {formik.errors["city"] && formik.touched["city"] ? (
                                <div className="error">{formik.errors["city"]}*</div>
                            ) : null}
                        </Grid>

                        <Grid sx={{ margin: "5px 0" }} item xs={12}>
                            <TextField
                                fullWidth
                                type="text"
                                id="postcode"
                                name="postcode"
                                label="Postal Code"
                                value={formik.values["postcode"] || ""}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched["postcode"] && Boolean(formik.errors["postcode"])}
                                helpertext={formik.touched["postcode"] && formik.errors["postcode"]}
                            />
                            {formik.errors["postcode"] && formik.touched["postcode"] ? (
                                <div className="error">{formik.errors["postcode"]}*</div>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>

            <Grid sx={{ padding: "20px 20px 20px 35px" }} container spacing={GRID_SPACING}>
                <Typography variant="h2" marginBottom={2}>Stats</Typography>
                <Grid container spacing={GRID_SPACING}>
                    <Grid sx={{ margin: "5px 0" }} item xs={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel id="payment_source_id">Source of Income</InputLabel>
                            <Select
                                id="payment_source_id"
                                name="payment_source_id"
                                label="Source of Income"
                                defaultValue={formik.values["payment_source_id"]}
                                value={formik.values["payment_source_id"] || []}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched["payment_source_id"] && Boolean(formik.errors["payment_source_id"])}
                                helpertext={formik.touched["payment_source_id"] && formik.errors["payment_source_id"]}
                                multiple={true}
                            >
                                {paymentSourceOptions.map((option, index) => (
                                    <MenuItem key={index} value={option['payment_source']}>
                                        {option['payment_source']}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid sx={{ margin: "5px 0" }} item xs={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel id="payment_purpose_id">Purpose of Service Usage</InputLabel>
                            <Select
                                id="payment_purpose_id"
                                name="payment_purpose_id"
                                label="Purpose of Service Usage"
                                defaultValue={formik.values["payment_purpose_id"]}
                                value={formik.values["payment_purpose_id"] || ""}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched["payment_purpose_id"] && Boolean(formik.errors["payment_purpose_id"])}
                                helpertext={formik.touched["payment_purpose_id"] && formik.errors["payment_purpose_id"]}
                            >
                                {paymentPurposeOptions.map((option, index) => (
                                    <MenuItem key={index} value={option['payment_purpose']}>
                                        {option['payment_purpose']}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid sx={{ margin: "5px 0" }} item xs={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel id="country_id">Countries to Send Money To</InputLabel>
                            <Select
                                id="country_id"
                                name="country_id"
                                label="Countries to Send Money To"
                                defaultValue={formik.values["country_id"]}
                                value={formik.values["country_id"] || []}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched["country_id"] && Boolean(formik.errors["country_id"])}
                                helpertext={formik.touched["country_id"] && formik.errors["country_id"]}
                                multiple={true}
                            >
                                {countriesOptions.map((option, index) => (
                                    <MenuItem key={index} value={option['country_name']}>
                                        {option['country_name']}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid sx={{ margin: "5px 0" }} item xs={12} md={4}>
                        <TextField
                            fullWidth
                            type="text"
                            id="transaction_limit"
                            name="transaction_limit"
                            label="Limit"
                            value={formik.values["transaction_limit"] || ""}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched["transaction_limit"] && Boolean(formik.errors["transaction_limit"])}
                            helpertext={formik.touched["transaction_limit"] && formik.errors["transaction_limit"]}
                        />
                        {formik.errors["transaction_limit"] && formik.touched["transaction_limit"] ? (
                            <div className="error">{formik.errors["transaction_limit"]}*</div>
                        ) : null}
                    </Grid>

                    <Grid sx={{ margin: "5px 0" }} item xs={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel id="risk_level">Risk Level</InputLabel>
                            <Select
                                id="risk_level"
                                name="risk_level"
                                label="Risk Level"
                                defaultValue={formik.values["risk_level"]}
                                value={formik.values["risk_level"] || ""}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched["risk_level"] && Boolean(formik.errors["risk_level"])}
                                helpertext={formik.touched["risk_level"] && formik.errors["risk_level"]}
                            >
                                {[1, 2, 3, 4, 5].map((option, index) => (
                                    <MenuItem key={index} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid sx={{ display: "flex", margin: "10px 0" }} item xs={12} md={4}>
                        <Checkbox
                            value={formik.values["is_pep"] || false}
                            id="is_pep"
                            name="is_pep"
                            label="Politically Exposed Person (PEP)?"
                            color="primary"
                            sx={{ color: theme.palette.primary.main }}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched["is_pep"] && Boolean(formik.errors["is_pep"])}
                            helpertext={formik.touched["is_pep"] && formik.errors["is_pep"]}
                        />
                        <Typography sx={{ paddingTop: 1.3 }}>Politically Exposed Person (PEP)?</Typography>
                        {formik.errors["is_pep"] && formik.touched["is_pep"] ? (
                            <div className="error">{formik.errors["is_pep"]}*</div>
                        ) : null}
                    </Grid>
                </Grid>
            </Grid>

            <Grid sx={{ margin: 2, display: "flex", justifyContent: "end" }}>
                <Button
                    sx={{
                        color: theme.palette.error.dark,
                        borderColor: theme.palette.error.dark
                    }}
                    onClick={onClose}
                    color="secondary"
                    type="reset"
                >
          Cancel
                </Button>
                <Button variant="contained" type="submit" autoFocus>Update</Button>
            </Grid>
        </form>
    );
};

export default AddEditCustomer;
