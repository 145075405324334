import axios from "axios";
import { decrypt, encrypt } from "./encDes";
import { toast } from 'react-toastify';

// Determine if encryption/decryption is enabled
const isEncEnabled = ["1", "true"].includes((process.env.REACT_APP_ENC_DEC_ENABLED || "0").toLowerCase());

// Create axios instance
const axiosServices = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:3010/"
});

// Utility to set API key headers based on request type
const setApiKeyHeader = (config) => {
  const method = config.method.toUpperCase();
  const apiKeyMapping = {
    GET: process.env.REACT_APP_GET_API_KEY,
    POST: process.env.REACT_APP_POST_API_KEY,
    PUT: process.env.REACT_APP_PUT_PATCH_API_KEY,
    PATCH: process.env.REACT_APP_PUT_PATCH_API_KEY,
    DELETE: process.env.REACT_APP_DELETE_API_KEY
  };

  config.headers["web-api-key"] = apiKeyMapping[method] || '';
};

// Request Interceptor
const handleRequest = (config) => {
  const serviceToken = localStorage.getItem("serviceToken");
  config.headers["x-api"] = process.env.REACT_APP_PLATFORM;

  if (serviceToken) {
    config.headers["Authorization"] = `Bearer ${serviceToken}`;
  }

  setApiKeyHeader(config);

  // Encrypt data if applicable
  if (isEncEnabled && ["POST", "PUT", "PATCH"].includes(config.method.toUpperCase())) {
    config.data = { payload: encrypt(JSON.stringify(config.data)) };
  }

  return config;
};

// Response Interceptor
const handleResponse = (response) => {
  if (response.data?.status === "SUCCESS") {
    let decryptedData = decrypt(response.data.data);

    // Check for mismatched encryption expectations
    if (!isEncEnabled && typeof decryptedData === "string") {
      // If decryption is attempted but encryption is disabled, show an error toast
      const errorMessage = "Decryption failed: Frontend expects unencrypted data, but encrypted data was received.";
      toast.error(errorMessage); // Show toast error message
      console.error(errorMessage);
      // throw new Error(errorMessage); // Throw an error to handle it in the catch block
    }

    try {
      response.data.data = parseData(decryptedData);
    } catch (error) {
      console.warn("Response data parsing failed", error);
      toast.error("Failed to parse response data."); // Show toast error message
    }
  }

  return response;
};

const parseData = (data) => {
  try {
    // Try parsing the value as JSON
    const parsedValue = JSON.parse(data);
    return parsedValue; // Return the parsed value
  } catch (error) {
    // If parsing fails, return the original value
    return data;
  }
};

// Error Handler
const handleError = (error) => {
  const status = error.response?.status || 500;
  const message = error.response?.data?.err?.message || error.response?.data?.message || error?.err?.message || 'An unexpected error occurred';

  console.error("Error occurred:", message); // Log error message
  toast.error(message); // Show error notification
  return Promise.reject({ status, message, ...error.response?.data });
};

// Attach interceptors
axiosServices.interceptors.request.use(handleRequest, Promise.reject);
axiosServices.interceptors.response.use(handleResponse, handleError);

export default axiosServices;
