import { lazy } from "react";
import MainLayout from "layout/MainLayout";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import CustomerDetails from "views/customer/details";
import { ROLES } from "constants";
import { Navigate } from "react-router-dom";
import MessageHistoryLog from "views/logs/message-history-log";

const Dashboard = Loadable(lazy(() => import("views/dashboard/index")));
const Orders = Loadable(lazy(() => import("views/orders/index")));
const Documentation = Loadable(lazy(() => import("views/documentation/index")));
const RelationWithRecipient = Loadable(lazy(() => import("views/settings/relation-with-recipient/index")));
const PaymentPurpose = Loadable(lazy(() => import("views/settings/payment-purpose/index")));
const PaymentSource = Loadable(lazy(() => import("views/settings/payment-source/index")));
const ServiceType = Loadable(lazy(() => import("views/settings/service-type/index")));
const ServiceNetwork = Loadable(lazy(() => import("views/settings/service-network/index")));
const DeliveryServices = Loadable(lazy(() => import("views/settings/delivery-services/index")));
const ExchangeRate = Loadable(lazy(() => import("views/settings/exchange-rate/index")));
const Countries = Loadable(lazy(() => import("views/settings/countries/index")));
const PartnersLog = Loadable(lazy(() => import("views/logs/partners-log/index")));
const AuditLog = Loadable(lazy(() => import("views/logs/audit-log/index")));
const Partners = Loadable(lazy(() => import("views/partners/index")));
const OnlineUsers = Loadable(lazy(() => import("views/online-users/index")));
const Compliance = Loadable(lazy(() => import("views/compliance/index")));
const UserComplianceHistory = Loadable(lazy(() => import("views/compliance/user-compliance-history")));
const RecipientComplianceHistory = Loadable(lazy(() => import("views/compliance/recipient-compliance-history")));
const Admin = Loadable(lazy(() => import("views/admin/index")));
const AllCustomer = Loadable(lazy(() => import("views/customer/index")));
const UnverifiedCustomer = Loadable(lazy(() => import("views/customer/unverified")));
const BlockedCustomer = Loadable(lazy(() => import("views/customer/blocked")));
const UnverifiedHistory = Loadable(lazy(() => import("views/customer/unverified-history")));
const AllRecipients = Loadable(lazy(() => import("views/recipients/index")));
const BlacklistRecipients = Loadable(lazy(() => import("views/recipients/blacklist")));

const MainRoutes = {
    path: "/",
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        // Dashboard Routes
        {
            path: "/dashboard",
            element: <Dashboard />,
            role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
        },
        // Orders
        {
            path: "/orders",
            element: <Orders />,
            role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
        },
        // user Routes
        {
            path: "/users",
            element: <Admin />,
            role: [ROLES.SUPER_ADMIN]
        },
        {
            path: "/accounts",
            element: <AllCustomer />,
            role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
        },
        {
            path: "/accounts/:userId",
            element: <CustomerDetails />,
            role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
        },

        {
            path: "/unverified-users",
            element: <UnverifiedCustomer />,
            role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
        },
        {
            path: "/unverified-history",
            element: <UnverifiedHistory />,
            role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
        },
        {
            path: "/blocked-users",
            element: <BlockedCustomer />,
            role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
        },
        {
            path: "/online-users",
            element: <OnlineUsers />,
            role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
        },
        // Compliance
        {
            path: "/compliance",
            element: <Compliance />,
            role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
        },
        {
            path: "/compliance/user-compliance-history",
            element: <UserComplianceHistory />,
            role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
        },
        {
            path: "/compliance/recipient-compliance-history",
            element: <RecipientComplianceHistory />,
            role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
        },
        // Recipient Routes
        {
            path: "/recipients",
            element: <AllRecipients />,
            role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
        },
        {
            path: "/blacklisted-recipients",
            element: <BlacklistRecipients />,
            role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
        },
        // Partners
        {
            path: "/partners",
            element: <Partners />,
            role: [ROLES.SUPER_ADMIN]
        },
        // Logs
        {
            path: "/logs/audit-logs",
            element: <AuditLog />,
            role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
        },
        {
            path: "/logs/partner-logs",
            element: <PartnersLog />,
            role: [ROLES.SUPER_ADMIN]
        },
        {
            path: "/logs/messages-history-logs",
            element: <MessageHistoryLog />,
            role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
        },
        {
            path: "/documentation",
            element: <Documentation />,
            role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
        },
        // Settings
        {
            path: "/configuration/countires",
            element: <Countries />,
            role: [ROLES.SUPER_ADMIN]
        },
        {
            path: "/configuration/currency-rate",
            element: <ExchangeRate />,
            role: [ROLES.SUPER_ADMIN]
        },
        {
            path: "/configuration/delivery-services",
            element: <DeliveryServices />,
            role: [ROLES.SUPER_ADMIN]
        },
        {
            path: "/configuration/delivery-services/network",
            element: <ServiceNetwork />,
            role: [ROLES.SUPER_ADMIN]
        },
        {
            path: "/configuration/delivery-services/type",
            element: <ServiceType />,
            role: [ROLES.SUPER_ADMIN]
        },
        {
            path: "/configuration/payment-source",
            element: <PaymentSource />,
            role: [ROLES.SUPER_ADMIN]
        },
        {
            path: "/configuration/payment-purpose",
            element: <PaymentPurpose />,
            role: [ROLES.SUPER_ADMIN]
        },
        {
            path: "/configuration/recipient-relation",
            element: <RelationWithRecipient />,
            role: [ROLES.SUPER_ADMIN]
        },
        {
            path: "*",
            element: <Navigate to="/dashboard" replace />,
            role: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
        }

    ]
};

export default MainRoutes;
