import { memo, useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Drawer, useMediaQuery } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import MenuList from "../MenuList";
import MiniDrawerStyled from "./MiniDrawerStyled";
import useConfig from "hooks/useConfig";
import { DRAWER_WIDTH, LAYOUT_CONST } from "constants";
import { useDispatch, useSelector } from "store";
import { openDrawer } from "store/slices/menu";

const Sidebar = () => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
    const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    const { drawerType } = useConfig();

    const drawerContent = <MenuList />;

    const drawerSX = {
        paddingLeft: drawerOpen ? "10px" : 0,
        paddingRight: drawerOpen ? "16px" : 0,
        marginTop: drawerOpen ? "80px" : "100px"
    };

    const drawer = useMemo(
        () => (
            <>
                {matchDownMd ? (
                    <Box sx={drawerSX}>{drawerContent}</Box>
                ) : (
                    <PerfectScrollbar
                        component="div"
                        style={{
                            // height: !matchUpMd ? "calc(100vh - 56px)" : "calc(100vh - 80px)",
                            ...drawerSX
                        }}
                    >
                        {drawerContent}
                    </PerfectScrollbar>
                )}
            </>
        ),
        [matchUpMd, drawerOpen, drawerType]
    );

    return (
        <Box
            component="nav"
            sx={{ flexShrink: { md: 0 }, width: matchUpMd ? DRAWER_WIDTH : "auto" }}
            aria-label="mailbox folders"
        >
            {matchDownMd ||
      (drawerType === LAYOUT_CONST.MINI_DRAWER && drawerOpen) ? (
                    <Drawer
                        variant={matchUpMd ? "persistent" : "temporary"}
                        anchor="left"
                        open={drawerOpen}
                        onClose={() => dispatch(openDrawer(!drawerOpen))}
                        sx={{
                            "& .MuiDrawer-paper": {
                                mt: matchDownMd ? 0 : 11,
                                zIndex: 1099,
                                width: DRAWER_WIDTH,
                                background: theme.palette.background.default,
                                color: theme.palette.text.primary,
                                borderRight: "none"
                            }
                        }}
                        ModalProps={{ keepMounted: true }}
                        color="inherit"
                    >
                        {matchDownMd}
                        {drawer}
                    </Drawer>
                ) : (
                    <MiniDrawerStyled variant="permanent" open={drawerOpen}>
                        {drawer}
                    </MiniDrawerStyled>
                )}
        </Box>
    );
};

export default memo(Sidebar);
