import { Outlet } from "react-router-dom";
import { lazy } from "react";
import GuestGuard from "utils/route-guard/GuestGuard";
import NavMotion from "layout/NavMotion";
import Loadable from "components/Loadable";
const AuthLogin = Loadable(lazy(() => import("views/authentication/Login")));
const AuthRequestPassword = Loadable(lazy(() => import("views/authentication/RequestPassword")));
const AuthResetPassword = Loadable(lazy(() => import("views/authentication/ResetPassword")));

const LoginRoutes = {
    path: "/",
    element: (
        <NavMotion>
            <GuestGuard>
                <Outlet />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            index: true,
            element: <AuthLogin />
        },
        {
            path: "/login",
            element: <AuthLogin />
        },
        {
            path: "/request-password",
            element: <AuthRequestPassword />
        },
        {
            path: "/reset-password",
            element: <AuthResetPassword />
        }
    ]
};

export default LoginRoutes;
