import { useTheme } from "@mui/material/styles";
import { Avatar, Box, Typography, useMediaQuery } from "@mui/material";
import { LAYOUT_CONST } from "constants";
import useConfig from "hooks/useConfig";
import ProfileSection from "./ProfileSection";
import { useDispatch, useSelector } from "store";
import { openDrawer } from "store/slices/menu";
import { IconMenu2 } from "@tabler/icons";

const Header = () => {
    const theme = useTheme();

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
    const { layout } = useConfig();

    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}
            >
                {drawerOpen && (
                    <Box
                        component="span"
                        sx={{ display: { xs: "none", md: "block" }, mr: 12 }}
                    >
                        <Typography style={{ color: "black", fontSize: "24px" }}>
              Malsom
                        </Typography>
                    </Box>
                )}
                {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || matchDownMd) && (
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            overflow: "hidden",
                            transition: "all .2s ease-in-out",
                            background:
                theme.palette.mode === "dark" ?
                    theme.palette.dark.main :
                    theme.palette.secondary.light,
                            color:
                theme.palette.mode === "dark" ?
                    theme.palette.secondary.main :
                    theme.palette.secondary.dark,
                            "&:hover": {
                                background:
                  theme.palette.mode === "dark" ?
                      theme.palette.secondary.main :
                      theme.palette.secondary.dark,
                                color:
                  theme.palette.mode === "dark" ?
                      theme.palette.secondary.light :
                      theme.palette.secondary.light
                            },
                            ml: { md: 0 }
                        }}
                        onClick={() => dispatch(openDrawer(!drawerOpen))}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="20px" />
                    </Avatar>
                )}
                <Box
                    sx={{
                        display: { xs: "none", md: "flex" },
                        alignItems: "center",
                        flexGrow: 1
                    }}
                />{" "}
            </Box>

            <ProfileSection />
        </>
    );
};

export default Header;
